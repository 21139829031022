import React, { useState, useEffect } from "react";
import {
    StyleSheet,
    Text,
    View,
    SafeAreaView,
    useWindowDimensions,
} from "react-native";
import PostScreen from "./PostsScreen";
import DailyHalacha from "./DailyHalacha";
import AllSubject from "./AllSubjects";
import Ads from "./Ads";
const HomeScreen = ({ navigation }) => {
    console.log("HomeScreen", new Date())
    const window = useWindowDimensions();
    const isWeb = window.width > 900
    return (
        <SafeAreaView style={{ flex: 1 }}>
            <View style={{ flex: 1, flexDirection: 'row' }}>
                <PostScreen navigation={navigation} />
                {isWeb &&
                    <View style={{ flex: 0.3, flexDirection: 'column', }}>
                        <AllSubject navigation={navigation} />
                        <View style={{ height: 7, width: '100%', backgroundColor: '#cdcdcd' }} />
                        <Ads />
                        <View style={{
                            width: '100%', backgroundColor: '#cdcdcd',
                            bottom: 0, height: 195, paddingTop: 10
                        }}>
                            <DailyHalacha navigation={navigation} />
                        </View>
                    </View>
                }
            </View>
        </SafeAreaView >
    )
}

export default HomeScreen