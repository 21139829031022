import { checkAuth } from '../../firebase/UserManagement';
import { useState, useEffect } from "react";
import listQuery from "../../firebase/ListFirestoreManager";

export default function getAds() {
    const currentUser = checkAuth();
    const [ads, setAds] = useState();
    useEffect(() => {
        if (currentUser && !ads) {
            async function getData() {
                const resultRef = await listQuery({
                    database: 'ads',
                    orderByField: 'updated_at',
                    direction: 'desc',
                    where: [
                        {
                            field: "active",
                            operator: "==",
                            value: true,
                        },
                    ]
                }).get()
                const result = resultRef.docs.map((doc) => ({ key: doc.id, ...doc.data() }))
                if (result.length > 1) {
                    let pos = 0
                    var display = () => {
                        setAds(result[pos])
                        let duration = result[pos].duration;
                        pos = (pos + 1) % result.length
                        setTimeout(display, duration);
                    }
                    display();
                } else if (result.length === 1) {
                    setAds(result[0])
                }
            }
            setTimeout(() => getData(), 3000);
        }
    })
    return ads
}