import React, { useState, useEffect, createRef, useRef } from 'react';

import {
    Button,
    Paragraph,
    Dialog,
    Portal,
} from "react-native-paper";
import {
    TextInput,
    StyleSheet
} from "react-native";

import { updateChat } from '../../common/UploaderApi'
let lastValue

const EditChatDialog = ({ hideDialog, item }) => {
    const [chat, setChat] = useState()
    if (lastValue !== item.message) {
        lastValue = item.message
        setChat(item.message)
    }
    const save = () => {
        updateChat(item, chat)
        hideDialog()
    }
    return (
        <Portal>
            <Dialog visible={item.message} onDismiss={hideDialog} style={{ maxWidth: 450, alignSelf: 'center' }}>
                <Dialog.Title>עריכה</Dialog.Title>
                <Dialog.Content>
                    <TextInput
                        style={styles.input}
                        underlineColorAndroid="transparent"
                        placeholderTextColor="#9a9a9c"
                        numberOfLines={5}
                        multiline={true}
                        value={chat}
                        onChangeText={text => setChat(text)}
                    />
                </Dialog.Content>
                <Dialog.Actions>
                    <Button onPress={hideDialog}>ביטול</Button>
                    <Button onPress={save}>שמירה</Button>
                </Dialog.Actions>
            </Dialog>
        </Portal>
    )
}
const styles = StyleSheet.create({
    input: {
        height: 40,
        textAlign: 'left',
        flex: 1,
        fontSize: 16,
        borderColor: '#cccccc',
        borderWidth: 1,
        borderRadius: 5,
        padding: 10,
        color: '#666666',
    },
})

export default EditChatDialog