import firebase from "firebase/app";
import "firebase/firestore";

export default function listQuery(query) {
    let q = firebase.firestore().collection(query.database);
    if (query.tag) {
        q = q.where("tags", "array-contains", query.tag)
    }
    if (query.where) {
        for (const where of query.where) {
            q = q.where(where.field, where.operator, where.value)
        }
    }
    if (query.limit) {
        q = q.limit(query.limit)
    }
    if (query.orderByField) {
        if (query.direction) {
            q = q.orderBy(query.orderByField, query.direction)
        } else {
            q = q.orderBy(query.orderByField)
        }
    }
    return q
}