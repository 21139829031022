import React, { useState, useEffect } from "react";
import {
    StyleSheet,
    Text,
    ActivityIndicator,
    SafeAreaView,
    FlatList,
    View,
    useWindowDimensions
} from "react-native";
import HTML from 'react-native-render-html';

import { getText } from './DailyLimudViewModel'
import theme from '../../../utils/Colors'

const DailyLimudDetailScreen = ({ route, navigation }) => {
    const contentWidth = useWindowDimensions().width;

    const { url } = route.params ?? {};
    const texts = getText(url)
    if (texts) {
        if (texts.error) {
            return <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
                <Text style={styles.title}>404 | הדף לא קיים</Text>
            </View>
        } else {
            let i = 0
            const data = texts.he.map(item => ({ key: (i++).toString(), date: item }))
            return <SafeAreaView style={{ flex: 1, paddingBottom: 8 }}>
                <FlatList
                    keyExtractor={(item) => item.key}
                    data={data}
                    renderItem={({ item }) => {
                        const parsedItem = Array.isArray(item.date) ? item.date.join('<br>') : item.date
                        const CONTENT = '<p style="font-size:22px;">' + parsedItem + '</p>'
                        return (
                            <View style={{ marginHorizontal: 16 }}>
                                <HTML source={{ html: CONTENT }} contentWidth={contentWidth} />
                            </View>
                        )
                    }}
                />
            </SafeAreaView>
        }
    } else {
        return <ActivityIndicator
            style={{ marginTop: 150 }}
            animating={true}
            color={theme.colors.primary}
        />
    }
}


const styles = StyleSheet.create({
    list: {
        flexGrow: 1,
        // marginTop: 8,
    },
    header: {
        fontSize: 20,
        color: '#666666',
        fontWeight: 'bold',
        height: 30,
        paddingLeft: 16,
        paddingRight: 16,
        marginTop: 13,
        marginBottom: 2,
    },
    title: {
        color: "#7A7A7A",
        fontSize: 30,
        fontWeight: "700",
        marginTop: 15,
    }
});

export default DailyLimudDetailScreen