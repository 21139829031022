import React from "react";
import {
    FlatList,
    View,
    StyleSheet
} from "react-native";
import ContentLoader from 'react-native-easy-content-loader'

const LoadingList = ({ num }) => {
    const items = []
    for (let i = 0; i < num * 2; i++) {
        items.push(i + "")
    }
    return (
        <FlatList
            style={styles.list}
            showsVerticalScrollIndicator={false}
            numColumns={num}
            keyExtractor={item => item}
            key={num}
            data={items}
            renderItem={({ }) => (
                <View style={{ flexDirection: 'row', flex: 1, marginTop: 25 }}>
                    <ContentLoader
                        active
                        tHeight={200}
                        tWidth={'100%'}
                        pRows={4}
                        pHeight={[15, 8, 10, 10]}
                        pWidth={['90%', '60%', '100%', '100%']}
                    />
                </View>
            )}
        />

    )
}
const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
    },
    list: {
        flexGrow: 1,
        marginTop: 20,
    },

})
export default LoadingList