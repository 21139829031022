import listQuery from '../../firebase/ListFirestoreManager'
import singleQuery from "../../firebase/SingleFirestoreManager"
import getData from '../../db/SectionData'
import { sortPosts, sortedPostsPerTags } from '../../tagged_posts/SortedPostPerTagRepo'
import { getSections } from '../../db/SectionsRepo'
import { checkAuth } from '../../firebase/UserManagement';
import { useState, useEffect } from "react";

let position = 0

function getPosts(tags) {
    return getData(listQuery, tags)
}

async function getPostSections(position) {
    //console.log('PostSections', 1, new Date())
    const postSectionsSnapshot = await getSections(position)
    let postSectionsRes = postSectionsSnapshot.docs.map((doc) => ({ tag: doc.id, ...doc.data() }))
    postSectionsRes = postSectionsRes.sort((a, b) => a.priority - b.priority)
    //console.log("sections", postSectionsRes.length)
    const tags = []
    const postsByTags = new Map()
    for (const p of postSectionsRes) {
        tags.push(p.tag)
        postsByTags.set(p.tag, [])
    }
    //console.log('PostSections', 2, new Date())
    const [result, sortPerTagRef] = await Promise.all([getPosts(tags), sortedPostsPerTags(tags).get()])
    //console.log('PostSections', 3, new Date())
    //console.log('posts', result)
    for (const post of result) {
        for (const tag of post.tags) {
            if (tags.includes(tag) && post.pined_in_tag[tag]) {
                postsByTags.get(tag).push(post)
            }
        }
    }
    const sortPerTagMap = new Map()
    sortPerTagRef.docs.forEach((doc) => {
        sortPerTagMap.set(doc.id, doc.data().post_ids)
    })

    const theResult = []
    for (const section of postSectionsRes) {
        const sortedPost = sortPosts(sortPerTagMap.get(section.tag), postsByTags.get(section.tag))
        theResult.push({
            tag: section.tag,
            title: section.iw,
            data: [sortedPost.slice(0, 8)],
        })
    }
    //console.log('PostSections', 4, new Date())
    return theResult
}

const fetchData = (selectedTab) => {
    //console.log('PostSections', -1, new Date())
    const currentUser = checkAuth();
    const [postsFirebase, setPosts] = useState();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (currentUser && position != selectedTab) {
            //console.log('PostSections', 0, new Date())
            if (position != 0) {
                setLoading(true)
            }
            position = selectedTab
            console.log("Query posts");
            async function fetchPostSections() {
                const postSections = await getPostSections(position)
                console.log("Query posts success");
                const dataCountRef = await singleQuery({
                    database: 'data_count',
                    doc: 'posts_count',
                })
                const dataCount = dataCountRef.data().count
                console.log("dataCount", dataCount);
                setPosts([postSections, dataCount])
                setLoading(false)
            }
            fetchPostSections();
        }
    });
    return { postsFirebase, loading };
};


export default fetchData