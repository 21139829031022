const theme = {
    colors: {
        primary: '#004e5f',
        accent: '#006176',
        light: "#F5F1E3",
        button: "#FFFFFF",
        textLight: "#FFFFFF",
        textDark: "#4E598C",
        input: "#DDDBCB",
    },
};

export default theme