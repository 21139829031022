import { getUid, isRabbi, getName } from "../firebase/UserManagement";

import firebase from "firebase/app";
import "firebase/firestore";

function updateChat(item, message) {
    const chatRef = firebase.firestore().collection("questions/" + item.question + "/conversations").doc(item.key)
    chatRef.update("message", message).then(() => {
        console.log("updateChat done");
    });
}

function deleteChat(item) {
    const chatRef = firebase.firestore().collection("questions/" + item.question + "/conversations").doc(item.key)
    chatRef.delete().then(() => {
        console.log("deleteChat done");
    });
}

function updateRabbiOpen(question, questionKey) {
    if (isRabbi()) {
        const status = question.rabbi_status[getUid()]
        let postRef = firebase.firestore().collection('questions').doc(questionKey)
        if (!status || status === '0' || status === '2' || (status === '3' && question.continues_question)) {
            question.rabbi_status[getUid()] = 1
            postRef.update("rabbi_status." + getUid(), 1).then(() => {
                console.log("updateRabbiOpen updated");
            });
        }
        if (question.answered == false && question.handler_name == null) {
            postRef.update("handler_name", getName()).then(() => {
                console.log("updateRabbiOpen updated");
            });
        }
    }
}


function updatePostStatistic(postId, fieldName) {
    if (isRabbi()) {
        return
    }
    const uid = getUid()
    const statisticRef = firebase.firestore().collection('post_statistic').doc(postId)
    const statistic = {}
    const inc = firebase.firestore.FieldValue.increment(1)
    statistic[fieldName + "_count"] = inc
    const t = {}
    t[uid] = inc
    statistic[fieldName + "_times_per_user"] = t
    statisticRef.set(statistic, { merge: true }).then(() => {
        console.log("statistic successfully updated");
    });
    const event = {
        type: fieldName,
        userId: uid,
        created_at: firebase.firestore.FieldValue.serverTimestamp(),
    }
    statisticRef.collection("events").add(event).then((docRef) => {
        console.log("Document written with ID: ", docRef.id);
    }).catch((error) => {
        console.error("Error adding document: ", error);
    });
}

export { updatePostStatistic, updateRabbiOpen, updateChat, deleteChat }