
import React from "react";
import {
    StyleSheet,
    FlatList,
    View,
    Text,
    SafeAreaView,
    useWindowDimensions,
    ActivityIndicator,
} from "react-native";
import SectionListItem from "../../components/SectionListItem";
import getPosts from "./TaggedPostsViewModel";
import theme from '../../utils/Colors'

export default function SelectedPostsByTag({ route, navigation }) {
    const window = useWindowDimensions();

    const { tag } = route.params;

    const [posts, notExist] = getPosts(tag);

    if (posts) {
        let num = 1;
        if (window.width > 1140) {
            num = 4;
        } else if (window.width > 880) {
            num = 3;
        } else if (window.width > 495) {
            num = 2;
        }
        return (
            <SafeAreaView style={{ flex: 1, paddingTop: 8 }}>
                <SectionListItem posts={posts} num={num} navigation={navigation} horizontal={false} />
            </SafeAreaView>
        )
    } else if (!tag || notExist) {
        return (
            <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
                <Text style={styles.title}>404 | הדף לא קיים</Text>
            </View>
        );
    } else {
        return (
            <ActivityIndicator
                style={{ marginTop: 150 }}
                animating={true}
                color={theme.colors.primary}
            />
        );
    }
}

const styles = StyleSheet.create({
    list: {
        flexGrow: 1,
        marginTop: 8,
    },
    title: {
        color: "#7A7A7A",
        fontSize: 30,
        fontWeight: "700",
        marginTop: 15,
    }
});