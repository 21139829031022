import React, { useState, useEffect } from 'react';
import {
    StyleSheet,
    View,
    FlatList,
    Text,
} from 'react-native';
import { Card, FAB } from "react-native-paper";
import theme from '../../utils/Colors'
import { removeLink } from '../../utils/TextAppearanceUtils'
import { isRabbi } from '../../firebase/UserManagement'

let rabbi

const QuestionItem = ({ item, onItemPress }) => (
    <View style={styles.item}>
        <Card onPress={onItemPress}>
            <Card.Title title={item.title} subtitle={removeLink(item.question)} />
            {rabbi && !item.answered && item.handler_name &&
                <Text style={{
                    fontFamily: 'Assistant',
                    paddingHorizontal: 15, color: '#ff9999', fontSize: 12, marginTop: -7,
                    marginBottom: 5
                }} >{"בטיפול: " + item.handler_name}</Text>
            }
        </Card>
    </View>
);

const QuestionsList = ({ navigation, questions, onItemPress }) => {
    rabbi = isRabbi()
    return (
        <View style={{ flex: 1 }}>
            {questions.length === 0 &&
                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={styles.empty}>לא מצאנו היסטוריית שאלות.{"\n"}ניתן ללחוץ על כפתור פלוס כדי לשלוח שאלה לרב.</Text>
                </View>
            }
            {questions.length > 0 &&
                <FlatList
                    data={questions}
                    keyExtractor={(item) => item.key}
                    renderItem={({ item }) => (
                        <QuestionItem item={item} onItemPress={() => onItemPress(item.key)} />
                    )}
                />
            }
            <FAB
                style={styles.fab}
                icon="plus"
                onPress={() => {
                    navigation.navigate("Question")
                }}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    item: {
        marginVertical: 3,
        marginHorizontal: 5,
    },
    title: {
        fontSize: 32,
    },
    fab: {
        position: "absolute",
        margin: 16,
        right: 0,
        bottom: 0,
        backgroundColor: theme.colors.accent,
    },
    empty: {
        fontFamily: 'Assistant',
        width: 300,
        marginTop: 24,
        padding: 30,
        backgroundColor: "#eeeeee",
        textAlign: "center",
        fontSize: 20,
        marginHorizontal: 12,
    },
});

export default QuestionsList