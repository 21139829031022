import React, { useState, useEffect } from "react";
import { checkAuth, isAnonymous, getUid, getEmail, getName } from "../../firebase/UserManagement";
import { updatePostStatistic } from '../../common/UploaderApi'
import firebase from "firebase/app";
import "firebase/firestore";

const getPost = (itemId) => {
    const [post, setPost] = useState();
    const [notExist, setNotExist] = useState();

    const currentUser = checkAuth();
    if (itemId) {
        useEffect(() => {
            if (!post && currentUser) {
                console.log("getPost", itemId);
                updatePostStatistic(itemId, "opened")
                const postRef = firebase.firestore().collection("posts").doc(itemId);
                postRef.onSnapshot(
                    (doc) => {
                        if (!doc.exists) {
                            console.log("not exist");
                            setNotExist(true);
                        } else {
                            const res = doc.data()
                            setPost(res);
                            const uid = getUid()
                            if (!res.opened_by_user[uid]) {
                                console.log("update opened_by_user")
                                const t = {}
                                t[uid] = true
                                const map = {
                                    opened_by_user: t
                                }
                                postRef.set(map, { merge: true }).then(() => {
                                    console.log("statistic successfully updated");
                                });
                            }
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            }
        });
    }
    return [post, notExist];
};

const sendComment = (postId, subject, question, postDetails, comment) => {
    return new Promise(response => {
        if (isAnonymous()) {
            response(-1)
        } else {
            const uid = getUid()
            const name = getName()
            const users_ids = {}
            users_ids[uid] = name
            const users_id_list = []
            users_id_list.push(uid)
            const questionObj = {
                title: subject,
                uid: uid,
                urgent_question: 0,
                question: comment,
                name: name,
                email: getEmail(),
                answered: false,
                updated_at: firebase.firestore.FieldValue.serverTimestamp(),
                timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                rabbi_status: {},
                new_messages: {},
                users_ids: users_ids,
                users_id_list: users_id_list,
                locked_discussion: false,
                status: 0,
                continues_question: false,
                picked_to_post: false,
                handler_name: null,
                handler_name: null,
                new_messages: {}
            }
            const questionRef = firebase.firestore().collection('questions').doc()
            questionRef.set(questionObj).then(() => {
                console.log("questionPart successfully updated");
                response(1)
            });
            const postById = "0"
            const postByName = "מאגר ההלכות"
            const conversationSource = {
                message: postDetails,
                message_type: 0,
                name: postByName,
                type: 0,
                uid: postById,
                timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            }
            questionRef.collection('conversations').add(conversationSource).then((docRef) => {
                console.log("Document written with ID: ", docRef.id);
            }).catch((error) => {
                console.error("Error adding document: ", error);
            });
            const conversationComment = {
                message: comment,
                message_type: 0,
                name: name,
                type: 0,
                uid: uid,
                timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            }
            questionRef.collection('conversations').add(conversationComment).then((docRef) => {
                console.log("Document written with ID: ", docRef.id);
            }).catch((error) => {
                console.error("Error adding document: ", error);
            });
            updatePostStatistic(postId, "message")
        }
    })
}

export { getPost, sendComment }