import React from "react";
import { StyleSheet, Image } from "react-native";
import { Avatar, Card, Paragraph } from "react-native-paper";
import { spanBoldAndEm, removeStars, spanEM } from '../utils/TextAppearanceUtils'

const LeftContent = (props) => (
  <Avatar.Icon {...props} icon="book-open-variant" />
);

function formatTime(timestamp) {
  const date = timestamp ? new Date(timestamp.toDate()) : new Date()
  return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
}

export default function CardItem({ item, onItemPress, num, horizontal, numberOfLines }) {
  const time = "תאריך: " + formatTime(item.updated_at)
  return (
    <Card onPress={onItemPress}
      style={{ flex: horizontal ? undefined : 1 / num, width: horizontal ? 300 : undefined, marginLeft: 12, marginRight: 12, marginBottom: 10 }}
    >
      {(!item.image_urls || item.image_urls.length == 0) && item.thumbnail && <Image style={{ flex: 1, width: '100%', height: 150 }} source={{ uri: item.thumbnail }} />}
      {item.image_urls && item.image_urls.length > 0 && <Image style={{ flex: 1, width: '100%', height: 150 }} source={{ uri: item.image_urls[0] }} />}

      <Card.Title titleStyle={{ fontFamily: 'Suez' }} subtitleStyle={{ fontFamily: 'Assistant' }} title={spanEM(removeStars(item.title), 'Suez')} subtitle={time} />
      <Card.Content>
        <Paragraph numberOfLines={numberOfLines}>{spanBoldAndEm(item.question)}</Paragraph>
      </Card.Content>
    </Card>
  );
}

const styles = StyleSheet.create({
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  avatar: {
    backgroundColor: "red",
  },
});
