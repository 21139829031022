import React, { useState, useEffect, createRef } from 'react';
import {
    StyleSheet,
    Text,
    View,
    TextInput,
    TouchableOpacity,
    SafeAreaView,
    useWindowDimensions,
} from 'react-native';
import { Paragraph, Dialog, Portal, Provider, Button, } from "react-native-paper"
import { Icon } from 'react-native-elements';
import { signUp, signIn, checkAuth, logout, forgotPassword, deleteAccount } from '../../firebase/UserManagement'

export default function LoginScreen({ navigation }) {
    const currentUser = checkAuth()
    const userName = currentUser ? currentUser.displayName : ""
    console.log('name', userName)
    const anonymous = currentUser ? currentUser.isAnonymous : true
    console.log('anonymous', anonymous)
    const window = useWindowDimensions();
    const [activeTab, setActiveTab] = useState('Login');
    function switchTab() {
        if (activeTab === 'Login') {
            setActiveTab('Register');
        } else {
            setActiveTab('Login');
        }
    }
    const [connectedVisible, setConnectedVisible] = useState(false);
    const [logoutVisible, setLogoutVisible] = useState(false);
    const [forgotVisible, setForgotVisible] = useState(false);
    const [deleteVisible, setDeleteVisible] = useState(false);


    const [showForgotPasswordView, setShowForgotPasswordView] = useState(false);
    const [showLoginPassword, setShowLoginPassword] = useState(false);

    const [nameRegisterField, setNameRegisterField] = useState('');
    const [emailRegisterField, setEmailRegisterField] = useState('');
    const [passwordRegisterField, setPasswordRegisterField] = useState('');

    const [emailLoginField, setEmailLoginField] = useState('');
    const [passwordLoginField, setPasswordLoginField] = useState('');

    const [emailForgotField, setEmailForgotField] = useState('');


    function ForgotPassword() {

        function submitForgotPassword() {
            forgotPassword(emailForgotField).then(() => {
                setForgotVisible(true)
                setShowForgotPasswordView(false)
            })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    alert(errorMessage)
                });
        }

        return (
            <View style={{ marginTop: 10 }}>
                <Text style={{ fontFamily: 'Assistant', fontSize: 17, marginHorizontal: 20, marginTop: 5 }}>אנחנו יכולים לשלוח לכתובת הזו הוראות לאיפוס הסיסמה.</Text>
                <View style={styles.inputView}>
                    <Icon
                        style={{ paddingHorizontal: 4 }}
                        name='envelope'
                        type='font-awesome'
                        color='#333333'
                        size={22}
                    />
                    <TextInput
                        key="1"
                        style={styles.input}
                        placeholder='אימייל'
                        placeholderTextColor='#9a9a9c'
                        keyboardType='email-address'
                        textContentType='emailAddress'
                        autoCompleteType='email'
                        returnKeyType='next'
                        onChangeText={email => setEmailForgotField(email)}
                    />
                </View>

                <Button labelStyle={{ fontFamily: 'AssistantMedium' }} icon="send-outline" mode="contained" style={{ marginTop: 20, width: 200, alignSelf: 'center', backgroundColor: 'rgb(0, 78, 95)' }} onPress={() => submitForgotPassword()}>שלח</Button>

                <TouchableOpacity onPress={() => setShowForgotPasswordView(false)}>
                    <Text style={styles.forgotPasswordText}>חזור</Text>
                </TouchableOpacity>

            </View>
        );

    }

    function Login() {

        function submitLogin() {
            signIn(emailLoginField, passwordLoginField).then(() => {
                // Signed in 
                //const user = userCredential.user;
                setConnectedVisible(true)
            })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    alert(errorMessage)
                });
        }

        return (
            <View style={{ marginTop: 10 }}>
                <View style={styles.inputView}>
                    <Icon
                        style={{ paddingHorizontal: 4 }}
                        name='envelope'
                        type='font-awesome'
                        color='#333333'
                        size={22}
                    />
                    <TextInput
                        key="1"
                        style={styles.input}
                        placeholder='אימייל'
                        placeholderTextColor='#9a9a9c'
                        keyboardType='email-address'
                        textContentType='emailAddress'
                        autoCompleteType='email'
                        returnKeyType='next'
                        onChangeText={email => setEmailLoginField(email)}
                    />
                </View>
                <View style={styles.inputView}>
                    <Icon
                        style={{ paddingHorizontal: 4 }}
                        name='key'
                        type='font-awesome-5'
                        color='#333333'
                        size={22}
                    />
                    <TextInput
                        key="2"
                        textAlign={'right'}
                        style={styles.input}
                        placeholder='סיסמא'
                        placeholderTextColor='#9a9a9c'
                        secureTextEntry={!showLoginPassword}
                        textContentType='password'
                        returnKeyType='done'
                        autoCompleteType='password'
                        onSubmitEditing={() => { submitLogin() }}
                        onChangeText={password => setPasswordLoginField(password)}
                    />
                    <TouchableOpacity
                        style={{ paddingVertical: 4 }}
                        onPress={() => {
                            setShowLoginPassword(!showLoginPassword);
                        }}
                    >
                        <Icon
                            style={{ paddingHorizontal: 4 }}
                            name='eye'
                            type='font-awesome'
                            color='#333333'
                            size={22}
                        />
                    </TouchableOpacity>
                </View>
                <Button icon="account" mode="contained" labelStyle={{ fontFamily: 'AssistantMedium' }} style={{ marginTop: 20, width: 200, alignSelf: 'center', backgroundColor: 'rgb(0, 78, 95)' }} onPress={() => submitLogin()}>סיום</Button>

                <TouchableOpacity onPress={() => setShowForgotPasswordView(true)}>
                    <Text style={styles.forgotPasswordText}>שכחת סיסמא?</Text>
                </TouchableOpacity>
            </View>
        );
    }

    const [showRegisterPassword, setShowRegisterPassword] = useState(false);

    function Register() {

        function submitRegister() {
            signUp(emailRegisterField, passwordRegisterField, nameRegisterField).then((user) => {
                if (user) {
                    setConnectedVisible(true)
                }
            })
        }

        return (
            <View style={{ marginTop: 10 }}>
                <View style={styles.inputView}>
                    <Icon
                        style={{ paddingHorizontal: 4, width: 30 }}
                        name='user'
                        type='font-awesome'
                        color='#333333'
                        size={22}
                    />
                    <TextInput
                        key="21"
                        style={styles.input}
                        placeholder='שם מלא'
                        placeholderTextColor='#9a9a9c'
                        textContentType='name'
                        autoCompleteType='name'
                        returnKeyType='next'
                        onChangeText={name => setNameRegisterField(name)}
                    />

                </View>
                <View style={styles.inputView}>
                    <Icon
                        style={{ paddingHorizontal: 4, width: 30 }}
                        name='envelope'
                        type='font-awesome'
                        color='#333333'
                        size={22}
                    />
                    <TextInput
                        key="22"
                        style={styles.input}
                        placeholder='אימייל'
                        placeholderTextColor='#9a9a9c'
                        keyboardType='email-address'
                        textContentType='emailAddress'
                        autoCompleteType='email'
                        returnKeyType='next'
                        onChangeText={email => setEmailRegisterField(email)}
                    />

                </View>
                <View style={styles.inputView}>
                    <Icon
                        style={{ paddingHorizontal: 4, width: 30 }}
                        name='key'
                        type='font-awesome-5'
                        color='#333333'
                        size={22}
                    />
                    <TextInput
                        key="23"
                        style={styles.input}
                        placeholder='סיסמא'
                        placeholderTextColor='#9a9a9c'
                        secureTextEntry={!showRegisterPassword}
                        textContentType='password'
                        returnKeyType='done'
                        onSubmitEditing={() => { submitRegister() }}
                        onChangeText={password => setPasswordRegisterField(password)}
                    />

                    <TouchableOpacity
                        style={{ paddingVertical: 4 }}
                        onPress={() => {
                            setShowRegisterPassword(!showRegisterPassword);
                        }}
                    >
                        <Icon
                            style={{ paddingHorizontal: 4 }}
                            name='eye'
                            type='font-awesome'
                            color='#333333'
                            size={22}
                        />
                    </TouchableOpacity>

                </View>
                <Button icon="account-plus" mode="contained" labelStyle={{ fontFamily: 'AssistantMedium' }} style={{ marginTop: 10, width: 200, alignSelf: 'center', backgroundColor: 'rgb(0, 78, 95)' }} onPress={() => submitRegister()}>הרשמה</Button>
                
            </View>
        );
    }
    function submitDelete() {
        deleteAccount().then(() => {
            setDeleteVisible(false)
            setLogoutVisible(true)
        }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            alert(errorMessage)
        });
    }
    function Logout() {
        function submitLogout() {
            logout().then(() => {
                setLogoutVisible(true)
            }).catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                alert(errorMessage)
            });
        }
        return (
            <View >
                <View style={{ flexDirection: 'row', alignSelf: 'center' }}>
                    <Text style={styles.welcomeText}>שלום, </Text>
                    <Text style={styles.welcomeText}>{userName}</Text>
                </View>
                <Button icon="logout" mode="contained" labelStyle={{ fontFamily: 'AssistantMedium' }} style={{ marginTop: 10, width: 200, alignSelf: 'center', backgroundColor: 'rgb(0, 78, 95)' }} onPress={() => submitLogout()}>התנתקות</Button>
                <Button icon="account-cancel-outline" mode="contained" labelStyle={{ fontFamily: 'AssistantMedium' }} style={{ marginTop: 10, width: 200, alignSelf: 'center', backgroundColor: 'rgb(0, 78, 95)' }} onPress={() => setDeleteVisible(true)}>מחיקת המשתמש</Button>
            </View>
        )
    }

    return (
        //<TouchableWithoutFeedback onPress={() => Keyboard.dismiss()}>
        <SafeAreaView style={styles.container}>
            {/* <View style={styles.bigCircle} />
            <View style={styles.smallCircle} /> */}
            <View style={{ alignItems: 'center' }}>
                {(currentUser && currentUser.isAnonymous) && !showForgotPasswordView &&
                    <View style={styles.authBox}>
                        <Text style={styles.welcomeText}>
                            {activeTab === 'Login' ? 'ברוך שובך' : 'הירשם עכשיו'}
                        </Text>
                        <View style={styles.switchTabsView}>
                            <TouchableOpacity
                                style={{
                                    borderBottomWidth: activeTab === 'Login' ? 4 : 0,
                                    borderBottomColor: '#333333',
                                    paddingHorizontal: 4,
                                    marginRight: 14,
                                }}
                                onPress={() => switchTab()}
                            >
                                <Text style={styles.switchText}>התחברות</Text>
                            </TouchableOpacity>
                            <TouchableOpacity
                                style={{
                                    borderBottomWidth: activeTab === 'Register' ? 4 : 0,
                                    borderBottomColor: '#333333',
                                    paddingHorizontal: 4,
                                    marginRight: 14,
                                }}
                                onPress={() => switchTab()}
                            >
                                <Text style={styles.switchText}>הרשמה</Text>
                            </TouchableOpacity>
                        </View>
                        {activeTab === 'Login' ? Login() : Register()}
                    </View>
                }
                {showForgotPasswordView &&
                    <View style={styles.authBox}>
                        {ForgotPassword()}
                    </View>
                }
                {(currentUser && !currentUser.isAnonymous) &&
                    <View style={styles.authBox}>
                        <Logout />
                    </View>
                }
            </View>
            <Portal>
                <Dialog visible={connectedVisible} onDismiss={() => setConnectedVisible(false)} style={{ maxWidth: 450, alignSelf: 'center' }}>
                    <Dialog.Title>ההתחברות בוצעה בהצלחה</Dialog.Title>
                    <Dialog.Content>
                        <Paragraph>כעת ניתן לשאול ולהתכתב עם הרב בנושאים הלכתיים.</Paragraph>
                    </Dialog.Content>
                    <Dialog.Actions>
                        <Button labelStyle={{ fontFamily: 'AssistantMedium' }} onPress={() => {
                            setConnectedVisible(false)
                            navigation.navigate("Home")
                        }}>סיום</Button>
                    </Dialog.Actions>
                </Dialog>
            </Portal>
            <Portal>
                <Dialog visible={logoutVisible} onDismiss={() => setLogoutVisible(false)} style={{ maxWidth: 450, alignSelf: 'center' }}>
                    <Dialog.Title>ההתנתקות בוצעה בהצלחה</Dialog.Title>
                    <Dialog.Actions>
                        <Button labelStyle={{ fontFamily: 'AssistantMedium' }} onPress={() => setLogoutVisible(false)}>סיום</Button>
                    </Dialog.Actions>
                </Dialog>
            </Portal>
            <Portal>
                <Dialog visible={forgotVisible} onDismiss={() => setForgotVisible(false)} style={{ maxWidth: 450, alignSelf: 'center' }}>
                    <Dialog.Title>שלחנו לך אימייל</Dialog.Title>
                    <Dialog.Content>
                        <Paragraph>כדי לשחזר את הסיסמא, עליך לפעול לפי ההוראות ששלחנו לכתובת המייל שלך.</Paragraph>
                    </Dialog.Content>
                    <Dialog.Actions>
                        <Button labelStyle={{ fontFamily: 'AssistantMedium' }} onPress={() => setForgotVisible(false)}>אישור</Button>
                    </Dialog.Actions>
                </Dialog>
            </Portal>
            <Portal>
                <Dialog visible={deleteVisible} onDismiss={() => setDeleteVisible(false)} style={{ maxWidth: 450, alignSelf: 'center' }}>
                    <Dialog.Title>מחיקת פרטי המשתמש לצמיתות</Dialog.Title>
                    <Dialog.Content>
                        <Paragraph>האם ברצונך למחוק את המשתמש שלך לצמיתות? לא ניתן יהיה לשחזר את המידע</Paragraph>
                    </Dialog.Content>
                    <Dialog.Actions>
                        <Button labelStyle={{ fontFamily: 'AssistantMedium' }} onPress={() => submitDelete()}>אישור</Button>
                        <Button labelStyle={{ fontFamily: 'AssistantMedium' }} onPress={() => setDeleteVisible(false)}>ביטול</Button>
                    </Dialog.Actions>
                </Dialog>
            </Portal>
        </SafeAreaView>
        //</TouchableWithoutFeedback>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingTop: 40,
    },
    bigCircle: {
        width: 455,
        height: 455,
        backgroundColor: 'rgb(19, 125, 149)',
        borderRadius: 1000,
        position: 'absolute',
        left: 0,
        top: -70,
    },
    smallCircle: {
        width: 265,
        height: 265,
        backgroundColor: 'rgb(55, 105, 116)',
        borderRadius: 1000,
        position: 'absolute',
        bottom: 0,
        right: 0,
    },
    welcomeText: {
        fontFamily: 'AssistantBold',
        alignSelf: 'center',
        fontSize: 35,
        marginTop: 10,
        color: '#666666',
    },
    switchTabsView: {
        display: 'flex',
        flexDirection: 'row',
        paddingHorizontal: 20,
        marginTop: 20,
    },
    switchText: {
        fontFamily: 'Assistant',
        padding: 2,
        fontSize: 20,
        color: '#333333',
        fontWeight: "600",
    },
    inputView: {
        height: 40,
        borderBottomWidth: 1,
        borderBottomColor: '#333333',
        marginTop: 10,
        marginHorizontal: 20,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    input: {
        fontFamily: 'Assistant',
        textAlign: 'left',
        flex: 1,
        height: 40,
        fontSize: 16,
        paddingHorizontal: 4,
        color: '#666666',
    },
    button: {
        marginHorizontal: 20,
        backgroundColor: '#fafafa',
        marginTop: 12,
        paddingVertical: 10,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
    buttonText: { fontSize: 16, color: 'rgb(19, 125, 149)' },
    forgotPasswordText: {
        fontFamily: 'Assistant',
        marginHorizontal: 20,
        marginTop: 20,
        alignSelf: 'flex-end',
        color: '#333333',
        fontSize: 18,
        fontWeight: "600",
    },
    socialLoginView: {
        marginTop: 30,
        marginHorizontal: 20,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    socialLoginTouchable: {
        backgroundColor: 'rgb(0, 78, 95)',
        width: 40,
        height: 40,
        borderRadius: 100,
        alignItems: 'center',
        justifyContent: 'center',
        marginHorizontal: 8,
    },
    authBox: {
        width: '90%',
        maxWidth: 600,
        backgroundColor: '#fafafa',
        borderRadius: 20,
        alignSelf: 'center',
        paddingHorizontal: 14,
        paddingBottom: 30,
        shadowColor: '#aaa',
        shadowOffset: {
            width: 0,
            height: 0.5,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
});