import listQuery from "../../firebase/ListFirestoreManager";
import { checkAuth, getUid } from '../../firebase/UserManagement';
import { useState, useEffect } from "react";
import { getSections } from '../../db/SectionsRepo'

async function getLastHalacha() {
    //console.log('LastHalacha', new Date())
    const position = 2
    const postSectionsSnapshot = await getSections(position)
    let postSectionsRes = postSectionsSnapshot.docs.map((doc) => ({ tag: doc.id, ...doc.data() }))
    postSectionsRes = postSectionsRes.sort((a, b) => a.priority - b.priority)
    //console.log("sections", postSectionsRes.length)
    //console.log('LastHalacha', new Date())
    const tag = postSectionsRes[0].tag
    const resultRef = await listQuery({
        database: 'posts',
        where: [
            {
                field: "pined_count",
                operator: ">",
                value: 0,
            }, {
                field: "tags",
                operator: "array-contains",
                value: tag,
            },
        ]
    }).get()
    //console.log('LastHalacha', new Date())
    const result = resultRef.docs.map((doc) => ({ key: doc.id, ...doc.data() }))
    result.sort((a, b) => b.updated_at.seconds - a.updated_at.seconds)
    //console.log('LastHalacha', new Date())
    return result[0]
}

export default function getDailyHalacha() {
    const currentUser = checkAuth();
    const [post, setPost] = useState();
    useEffect(() => {
        if (currentUser && !post) {
            async function getData() {
                const result = await getLastHalacha()
                result.title = "הלכה יומית"
                setPost(result)
            }
            setTimeout(() => getData(), 2000);

        }
    })
    return post
}