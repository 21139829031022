import React, { useMemo, useState } from "react";
import { Image, StyleSheet, View } from "react-native";

const ResponsiveImage = ({ source }) => {
    const [containerWidth, setContainerWidth] = useState(0);
    const _onViewLayoutChange = event => {
        const { width } = event.nativeEvent.layout;
        setContainerWidth(width);
    }
    const [ratio, setRatio] = React.useState(1);
    Image.prefetch(source.uri)
        .then(() => {
            Image.getSize(source.uri, (width, height) => {
                let aspectRatio = height / width;
                setRatio(aspectRatio);
            });
        })
    const imageStyles = useMemo(() => {
        return {
            width: containerWidth,
            height: containerWidth * ratio
        };
    }, [containerWidth, ratio]);
    return (
        <View style={styles.container} onLayout={_onViewLayoutChange}>
            {ratio !== 1 &&
                <Image source={source} style={imageStyles} />
            }
        </View>
    );
};

const styles = StyleSheet.create({
    container: { width: "100%" }
});

export default ResponsiveImage;
