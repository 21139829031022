import React, { useState, useEffect, createRef } from "react";
import GetLocation from 'react-native-get-location'
import { getZmanimJson } from "kosher-zmanim";
import * as Localization from 'expo-localization';
import * as Location from 'expo-location';
import { Platform } from 'react-native';
//import DeviceInfo from 'react-native-device-info'

function getZmanim() {
    const [zmanim, setZmaim] = useState(null);
    useEffect(() => {
        (async () => {
            let { status } = await Location.requestForegroundPermissionsAsync();
            if (status !== 'granted') {
                console.log('Permission to access location was denied');
                return;
            }
            let location = await Location.getCurrentPositionAsync();
            let timezone = Localization.timezone
            const option = {
                date: new Date(),
                latitude: location.coords.latitude,
                longitude: location.coords.longitude,
                timeZoneId: timezone
            }
            let zmanimJson = getZmanimJson(option).BasicZmanim
            const res = []
            let i = 0
            res.push({ key: (i++).toString(), name: "עלות השחר 72 דקות", value: repormatTime(zmanimJson.Alos72) })
            res.push({ key: (i++).toString(), name: "עלות השחר", value: repormatTime(zmanimJson.AlosHashachar) })
            res.push({ key: (i++).toString(), name: "זריחה", value: repormatTime(zmanimJson.Sunrise) })
            res.push({ key: (i++).toString(), name: "סוף זמן קריאת שמע - מגן אברהם", value: repormatTime(zmanimJson.SofZmanShmaMGA) })
            res.push({ key: (i++).toString(), name: 'סוף זמן קריאת שמע - הגר"א', value: repormatTime(zmanimJson.SofZmanShmaGRA) })
            res.push({ key: (i++).toString(), name: "סוף זמן תפילה - מגן אברהם", value: repormatTime(zmanimJson.SofZmanTfilaMGA) })
            res.push({ key: (i++).toString(), name: 'סוף זמן תפילה - הגר"א', value: repormatTime(zmanimJson.SofZmanTfilaGRA) })
            res.push({ key: (i++).toString(), name: "חצות היום והלילה", value: repormatTime(zmanimJson.Chatzos) })
            res.push({ key: (i++).toString(), name: "מנחה גדולה", value: repormatTime(zmanimJson.MinchaGedola) })
            res.push({ key: (i++).toString(), name: "מנחה קטנה", value: repormatTime(zmanimJson.MinchaKetana) })
            res.push({ key: (i++).toString(), name: "פלג המנחה", value: repormatTime(zmanimJson.PlagHamincha) })
            if (new Date().getDay() === 6) {
                res.push({ key: (i++).toString(), name: "הדלקת נרות", value: repormatTime(zmanimJson.CandleLighting) })
            }
            res.push({ key: (i++).toString(), name: "שקיעה", value: repormatTime(zmanimJson.Sunset) })
            res.push({ key: (i++).toString(), name: "צאת הכוכבים", value: repormatTime(zmanimJson.EndCivilTwilight) })
            res.push({ key: (i++).toString(), name: "צאת הכוכבים - רבינו תם", value: repormatTime(zmanimJson.Tzais72) })
            setZmaim(res)
        })();
    }, []);
    return zmanim
}

function repormatTime(dateStr) {
    const date = new Date(dateStr)
    const hour = date.getHours() > 9 ? date.getHours() : "0" + date.getHours()
    const minutes = date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes()
    return hour + ":" + minutes
}

export default getZmanim