import React, { useState, useEffect } from "react";

import {
    StyleSheet,
    Text,
    View,
    SafeAreaView,
    useWindowDimensions,
} from "react-native";
import CardItem from "../../components/CardItem";
import getDailyHalacha from "./DailyHalachaViewModel";

const DailyHalacha = ({ navigation }) => {

    const post = getDailyHalacha()
    const onItemPress = () => navigation.navigate("Details", { itemId: post.key })
    if (post) {
        return <CardItem item={post} onItemPress={onItemPress} num={1} horizontal={false} numberOfLines={4} />
    } else {
        return <View />
    }
}

export default DailyHalacha