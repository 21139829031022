import 'react-native-gesture-handler';
import { registerRootComponent } from 'expo';

import App from './App';
import { configureFonts, DefaultTheme, Provider as PaperProvider } from 'react-native-paper';
import React from 'react';
import { useFonts } from 'expo-font'

const fontConfig = {
  web: {
    regular: {
      fontFamily: 'Assistant',
      fontWeight: 'normal',
    },
    medium: {
      fontFamily: 'AssistantMedium',
      fontWeight: 'normal',
    },
    light: {
      fontFamily: 'AssistantLight',
      fontWeight: 'normal',
    },
    thin: {
      fontFamily: 'Assistant',
      fontWeight: 'normal',
    },
  },
  ios: {
    regular: {
      fontFamily: 'Assistant',
      fontWeight: 'normal',
    },
    medium: {
      fontFamily: 'AssistantMedium',
      fontWeight: 'normal',
    },
    light: {
      fontFamily: 'AssistantLight',
      fontWeight: 'normal',
    },
    thin: {
      fontFamily: 'Assistant',
      fontWeight: 'normal',
    },
  },
  android: {
    regular: {
      fontFamily: 'sans-serif',
      fontWeight: 'normal',
    },
    medium: {
      fontFamily: 'sans-serif-medium',
      fontWeight: 'normal',
    },
    light: {
      fontFamily: 'sans-serif-light',
      fontWeight: 'normal',
    },
    thin: {
      fontFamily: 'sans-serif-thin',
      fontWeight: 'normal',
    },
  }
}

const theme = {
  ...DefaultTheme,
  fonts: configureFonts(fontConfig),
};

export default function Main() {
  const [loaded] = useFonts({
    Assistant: require('./src/assets/fonts/Assistant-Regular.ttf'),
    AssistantLight: require('./src/assets/fonts/Assistant-Light.ttf'),
    AssistantMedium: require('./src/assets/fonts/Assistant-Medium.ttf'),
    AssistantBold: require('./src/assets/fonts/Assistant-Bold.ttf'),
    Suez: require('./src/assets/fonts/SuezOne-Regular.ttf'),
  });
  if (!loaded) {
    return null;
  }
  return (
    <PaperProvider theme={theme}>
      <App />
    </PaperProvider>
  );
}
// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately
registerRootComponent(Main);
