import React, { useState, useEffect } from "react";

import {
    StyleSheet,
    Text,
    View,
    SafeAreaView,
    useWindowDimensions,
    Image,
    TouchableHighlight,
    Linking,
    Platform,
} from "react-native";

import * as WebBrowser from 'expo-web-browser';

import getAds from "./AdsViewModel"
import ResponsiveImage from "../../components/ResponsiveImage";

const Ads = () => {
    const ad = getAds()
    if (ad) {
        return (
            <TouchableHighlight onPress={() => {
                const url = ad.link
                if (url !== "") {
                    WebBrowser.openBrowserAsync(url)
                }
            }} style={{
                width: '100%',
            }}>
                <ResponsiveImage source={{ uri: ad.imageUrl }} />
            </TouchableHighlight>
        )
    } else {
        return <View />
    }
}

export default Ads