import React, { useState, useEffect } from "react";
import {
    StyleSheet,
    useWindowDimensions,
    ActivityIndicator,
    SafeAreaView,
    FlatList,
} from "react-native";
import { Card } from "react-native-paper";

import { getDailyLimud } from './DailyLimudViewModel'
import theme from '../../../utils/Colors'


const DailyLimudScreen = ({ navigation }) => {
    const window = useWindowDimensions();
    const dailyLimud = getDailyLimud(0)
    let num = 1;
    if (window.width > 1140) {
        num = 4;
    } else if (window.width > 880) {
        num = 3;
    } else if (window.width > 495) {
        num = 2;
    }
    if (dailyLimud) {
        return <SafeAreaView style={{ flex: 1, paddingTop: 8 }}>
            <FlatList
                keyExtractor={(item) => item.ref}
                data={dailyLimud}
                numColumns={num}
                key={num}
                renderItem={({ item }) => (
                    <Card onPress={() => {
                        navigation.navigate("DailyLimudDetail", { url: item.url });
                    }} style={{ flex: 1 / num, marginLeft: 12, marginRight: 12, marginBottom: 15 }}>
                        <Card.Title title={item.title.he} subtitle={item.displayValue.he} />
                    </Card>
                )}
            />
        </SafeAreaView>
    } else {
        return <ActivityIndicator
            style={{ marginTop: 150 }}
            animating={true}
            color={theme.colors.primary}
        />
    }
}

const styles = StyleSheet.create({
    list: {
        flexGrow: 1,
        // marginTop: 8,
    },
    header: {
        fontSize: 20,
        color: '#666666',
        fontWeight: 'bold',
        height: 30,
        paddingLeft: 16,
        paddingRight: 16,
        marginTop: 13,
        marginBottom: 2,
    },
});

export default DailyLimudScreen