import React from "react";
import {
    Button,
    Paragraph,
    Dialog,
    Portal,
} from "react-native-paper";

const ConnectDialog = ({ visible, hideDialog, navigation }) => {
    const continueDialog = () => {
        hideDialog()
        navigation.navigate("Login")
    }
    return (
        <Portal>
            <Dialog visible={visible} onDismiss={hideDialog} style={{ maxWidth: 450, alignSelf: 'center' }}>
                <Dialog.Title>הרשמה</Dialog.Title>
                <Dialog.Content>
                    <Paragraph>ביצוע קצר של רישום למאגר השו"ת מקנה לך שפע של אפשרויות ואיכות גלישה מיוחדת:{"\n"}1. אפשרות לשאול שאלות,{"\n"}2. אפשרות להוסיף תגובות, {"\n"}3. אפשרות להתכתב בנושאים הלכתיים עם הרב.</Paragraph>
                </Dialog.Content>
                <Dialog.Actions>
                    <Button labelStyle={{ fontFamily: 'AssistantMedium' }} onPress={hideDialog}>ביטול</Button>
                    <Button labelStyle={{ fontFamily: 'AssistantMedium' }} onPress={continueDialog}>המשך</Button>
                </Dialog.Actions>
            </Dialog>
        </Portal>
    )
}

export default ConnectDialog