import {
  StyleSheet,
  Text,
  View,
  useWindowDimensions,
  ActivityIndicator,
  Image,
  SafeAreaView,
  ScrollView,
  TextInput,
  Platform,
} from "react-native";
import * as WebBrowser from 'expo-web-browser';

import React, { useState, useEffect, createRef } from "react";
import {
  Title, Headline, Paragraph, Appbar,
  Button,
  Dialog,
  Portal,
  Card,
} from "react-native-paper";
import { getPost, sendComment } from './PostDetailsViewModel'
import ConnectDialog from "../../common/ConnectDialog";
import theme from '../../utils/Colors'
import { spanBoldAndEm, removeStars } from '../../utils/TextAppearanceUtils'


function postDetails(post) {
  let result = "✒ *"
  result += post.title
  result += "* ✒\n\n"
  result += post.question
  if (post.answer) {
    result += "\n\n*"
    result += "תשובה"
    result += "*\n"
    result += post.answer
  }
  if (post.source) {
    result += "\n*"
    result += "מקור"
    result += "*\n"
    result += post.source
  }
  return result
}

export default function DetailsScreen({ route, navigation }) {
  const windowDimensions = useWindowDimensions();
  let isMobile = windowDimensions.width <= 1100;
  const { itemId } = route.params ?? {};
  const [post, notExist] = getPost(itemId);

  //message input start
  const [connectVisible, setConnectVisible] = useState(false);
  const showConnectDialog = () => setConnectVisible(true);
  const hideConnectDialog = () => setConnectVisible(false);

  const [visible, setVisible] = useState(false);
  const showDialog = () => setVisible(true);
  const hideDialog = () => setVisible(false);
  const continueDialog = () => {
    hideDialog()
    navigation.navigate("Chat")
  }

  const [inputMessage, setInputMessage] = useState('');
  const myTextInput = createRef()
  function sendMessage() {
    if (inputMessage === '') {
      return
    }
    sendComment(post.id, post.title, post.question, postDetails(post), inputMessage).then(response => {
      if (response === -1) {
        showConnectDialog()
      } else if (response === 1) {
        showDialog()
      }
    })
    setInputMessage('');
    myTextInput.current.clear()
  }
  // message input end
  useEffect(() => {
    if (post) {
      navigation.setOptions({
        title: post.title
      })
    }
  })

  if (post) {
    return (
      <SafeAreaView style={{ flex: 1 }}>
        <ScrollView>
          <View style={{ 'width': isMobile ? '100%' : '60%', alignSelf: 'center' }}>
            {/* {post.thumbnail && <Image style={{ flex: 1, width: '100%', height: 200 }} source={{ uri: post.thumbnail }} />} */}
            {post.image_urls !== undefined && post.image_urls.length > 0 && <Image style={{ width: '100%', height: 300, marginBottom: 7 }} source={{ uri: post.image_urls[0] }} />}
            {post.question !== undefined && <Paragraph style={styles.body}>{spanBoldAndEm(post.question)}</Paragraph>}
            {post.answer !== undefined && post.answer.length > 0 && <Title style={{ marginHorizontal: 16, marginTop: 3 }}>תשובה:</Title>}
            {post.answer !== undefined && post.answer.length > 0 && <Paragraph style={styles.body}>{spanBoldAndEm(post.answer)}</Paragraph>}
            {post.source !== undefined && post.source.length > 0 && <Title style={{ marginHorizontal: 16, marginTop: 3 }}>מקור:</Title>}
            {post.source !== undefined && post.source.length > 0 && <Paragraph style={styles.body}>{spanBoldAndEm(post.source)}</Paragraph>}
            {post.pdf_url &&
              <Card style={styles.card} onPress={() => {
                console.log(post.pdf_url)
                WebBrowser.openBrowserAsync(post.pdf_url);
              }}>
                <Image style={{
                  width: '100%', height: 300,
                  borderTopLeftRadius: 5,
                  borderTopRightRadius: 5,
                }} source={{ uri: post.thumbnail }} />
                <View style={{
                  backgroundColor: '#ededed', height: 48,
                  alignItems: 'center', paddingStart: 16, flexDirection: 'row'
                }}>
                  <Image style={{
                    width: 25, height: 25,
                  }} source={require('../../assets/images/icon_file_pdf.png')} />
                  <Text style={{ fontSize: 14, fontWeight: "400", color: '#222222', marginStart: 10, }}>{post.pdf_name}</Text>
                </View>
              </Card>
            }
            <View style={{ height: 30 }}></View>
          </View>
        </ScrollView>
        <View style={{ paddingTop: 5, 'width': isMobile ? '100%' : '60%', alignSelf: 'center' }}>
          <View style={styles.messageInputView}>
            <TextInput
              ref={myTextInput}
              style={styles.messageInput}
              placeholder='כתוב תגובה'
              onChangeText={(text) => setInputMessage(text)}
              onSubmitEditing={() => { sendMessage() }}
            />
            <Appbar.Action icon={{ source: "send", direction: 'rtl' }} onPress={() => sendMessage()} color='#333333' style={{ alignSelf: 'center', marginEnd: 10 }} />
          </View>
        </View>
        <ConnectDialog visible={connectVisible} hideDialog={hideConnectDialog} navigation={navigation} />
        <Portal>
          <Dialog visible={visible} onDismiss={hideDialog} style={{ maxWidth: 450, alignSelf: 'center' }}>
            <Dialog.Title>תודה רבה</Dialog.Title>
            <Dialog.Content>
              <Paragraph>תגובתך נשלחה בהצלחה ונשתדל להשיב בהקדם.</Paragraph>
            </Dialog.Content>
            <Dialog.Actions>
              <Button labelStyle={{ fontFamily: 'AssistantMedium' }} onPress={continueDialog}>סיום</Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>
      </SafeAreaView>
    );
  } else if (!itemId || notExist) {
    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <Text style={styles.title}>404 | הדף לא קיים</Text>
      </View>
    );
  } else {
    return (
      <ActivityIndicator
        style={{ marginTop: 150 }}
        animating={true}
        color={theme.colors.primary}
      />
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
    alignItems: "center",
    justifyContent: "center",
  },
  buttons: {
    justifyContent: "center",
    width: "90%",
    marginTop: 100,
    backgroundColor: "red",
    height: 40,
    alignItems: "center",
  },
  headingTitle: {
    color: "#54595F",
    fontSize: 40,
    fontWeight: "500",
  },
  title: {
    color: "#7A7A7A",
    fontSize: 30,
    fontWeight: "700",
    marginTop: 15,
  },
  body: {
    fontSize: 18,
    marginHorizontal: 16,
  },
  messageInputView: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#ededed',
  },
  messageInput: {
    fontFamily: 'Assistant',
    textAlign: 'left',
    height: 40,
    flex: 1,
    paddingHorizontal: 10,
    backgroundColor: 'white',
    borderRadius: 21,
    alignSelf: 'center',
    marginStart: 16,
    marginEnd: 5,
  },
  messageSendView: {
    paddingHorizontal: 10,
    justifyContent: 'center',
  },
  card: {
    width: 250,
    alignSelf: 'center',
    marginTop: 10,
  }
});
