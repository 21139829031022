import { getUid, getName, isRabbi, checkAuth } from '../../firebase/UserManagement'
import firebase from "firebase/app";
import "firebase/firestore"
import "firebase/storage"
import singleQuery from "../../firebase/SingleFirestoreManager";
import listQuery from "../../firebase/ListFirestoreManager";
import { useState, useEffect } from "react";
import { updateRabbiOpen } from '../../common/UploaderApi'

import uuid from "uuid";
async function getQuestion(questionKey) {
    const dataRef = await singleQuery({
        database: 'questions',
        doc: questionKey
    })
    return dataRef.data()
}

function getConversation(questionKey) {
    const currentUser = checkAuth();
    const [conversation, setConversations] = useState();
    const [question, setQuestion] = useState();
    useEffect(() => {
        if (currentUser && questionKey) {
            console.log("getConversation", questionKey)
            async function getData() {
                const question = await getQuestion(questionKey)
                if (question) {
                    updateRabbiOpen(question, questionKey)
                }
                setQuestion(question)
            }
            getData()
            listQuery({
                database: "questions/" + questionKey + "/conversations",
                orderByField: "timestamp"
            }).onSnapshot((querySnapshot) => {
                const conversations = [];
                querySnapshot.forEach((doc) => {
                    conversations.push({ key: doc.id, question: questionKey, ...doc.data() });
                });
                setConversations(conversations)
            });

        }
    }, [currentUser, questionKey])
    return [conversation, question]
}

function sendChat(questionKey, message, messageType) {
    console.log(questionKey, message, messageType)
    const questionRef = firebase.firestore().collection('questions').doc(questionKey)
    const conversationsDoc = questionRef.collection('conversations')
    const rabbi = isRabbi()
    const conversation = {
        message: message,
        message_type: messageType,
        name: getName(),
        type: rabbi ? 1 : 0,
        uid: getUid(),
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    }
    conversationsDoc.add(conversation)
        .then((docRef) => {
            console.log("Document written with ID: ", docRef.id);
        })
        .catch((error) => {
            console.error("Error adding document: ", error);
        });

    const questionPart = {
        answered: rabbi,
        status: rabbi ? 1 : 0,
        continues_question: true,
        question: messageType === 0 ? message : ("\uD83D\uDCF7" + " תמונה")
    }
    if (rabbi) {
        questionPart['handler_name'] = null
    }
    questionRef.set(questionPart, { merge: true }).then(() => {
        console.log("questionPart successfully updated");
    });
}

async function sendImage(questionKey, image) {
    const imageUri = image.uri
    const blob = await new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = function() {
          resolve(xhr.response);
        };
        xhr.onerror = function(e) {
          console.log(e);
          reject(new TypeError('Network request failed'));
        };
        xhr.responseType = 'blob';
        xhr.open('GET', imageUri, true);
        xhr.send(null);
      });

      const ref = firebase
        .storage()
        .ref()
        .child("photos").child(uuid.v4());
      const snapshot = await ref.put(blob);

      // We're done with the blob, close and release it
      //  blob.close();

      const downloadUri =  await snapshot.ref.getDownloadURL();
      sendChat(questionKey, downloadUri, 1)
}

export { sendImage, sendChat, getQuestion, getConversation }