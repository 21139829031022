

const DEFAULT = [
    '#f16364',
    '#f58559',
    '#f9a43e',
    '#e4c62e',
    '#67bf74',
    '#59a2be',
    '#2093cd',
    '#ad62a7',
    '#805781'
]

function getColorForText(text) {
    return DEFAULT[Math.abs(text.hashCode()) % DEFAULT.length]
}

String.prototype.hashCode = function () {
    var hash = 0;
    for (var i = 0; i < this.length; i++) {
        var character = this.charCodeAt(i);
        hash = ((hash << 5) - hash) + character;
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
}

export default getColorForText