
export default function getData(postRepository, tagsSource) {
    return new Promise((res) => {
        // don't run if there aren't any tags
        if (!tagsSource || !tagsSource.length) return res([]);

        let batches = [];
        let tags = [...tagsSource]
        while (tags.length) {
            // firestore limits batches to 10
            const batch = tags.splice(0, 10);

            // add the batch request to to a queue
            batches.push(
                new Promise(response => {
                    postRepository({
                        database: 'posts',
                        where: [
                            {
                                field: "pined_count",
                                operator: ">",
                                value: 0,
                            },
                            {
                                field: "tags",
                                operator: "array-contains-any",
                                value: [...batch],
                            }
                        ]
                    }).get().then(results => {
                        response(results.docs.map((doc) => ({ key: doc.id, ...doc.data() })))
                    })
                })
            )
        }

        // after all of the data is fetched, return it
        Promise.all(batches).then(content => {
            res(content.flat());
        })
    })
}