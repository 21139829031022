import singleQuery from "../firebase/SingleFirestoreManager";
import listQuery from "../firebase/ListFirestoreManager";

function sortedPostsPerTag(tag) {
    return singleQuery({
        database: "sorted_posts_per_tag",
        doc: tag
    })
}

function sortedPostsPerTags(tags) {
    return listQuery({
        database: "sorted_posts_per_tag",
        where: [
            {
                field: 'tag',
                operator: 'in',
                value: tags,
            }
        ],
    })
}

function sortPosts(post_ids, posts) {
    let source = [...posts]
    const sorted = []
    if (post_ids && post_ids.length > 0) {
        for (const id of post_ids) {
            for (const [i, post] of source.entries()) {
                if (post.key === id) {
                    sorted.push(post)
                    //source = source.filter(item => item !== post)
                    source.splice(i, 1)
                    break
                }
            }
        }
    }
    const result = []
    result.push(...source, ...sorted)
    return result
}

export { sortedPostsPerTag, sortedPostsPerTags, sortPosts }