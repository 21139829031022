import firebase from "firebase/app";
import "firebase/auth";
import { useState, useEffect } from "react";

const checkAuth = () => {
  const [currentUser, setUser] = useState();
  useEffect(() => {
    if (!currentUser) {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          console.log("User is authenticated now!");
          setUser(user);
        } else {
          console.log("User is not authenticated");
          firebase
            .auth()
            .signInAnonymously()
            .then(() => {
              console.log("firebaseSignIn done");
            });
        }
      });
    }
  });
  return currentUser;
};

function isRabbi() {
  const user = firebase.auth().currentUser
  if (user.photoURL) {
    const getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1)
    const userType = getLastItem(user.photoURL)
    return userType !== '0'
  }
  return false
}

function getUid() {
  return firebase.auth().currentUser.uid
}

function isAnonymous() {
  const user = firebase.auth().currentUser
  return !user || user.isAnonymous
}

function getName() {
  const user = firebase.auth().currentUser
  if (!user) {
    return ""
  } else {
    return user.displayName
  }
}

function getEmail() {
  const user = firebase.auth().currentUser
  if (!user) {
    return ""
  } else {
    return user.email
  }
}

async function signUp(email, password, name) {
  if (!name) {
    return alert("The name must not be empty.")
  }
  return firebase.auth().createUserWithEmailAndPassword(email, password)
    .then(async (userCredential) => {
      // Signed up 
      const user = userCredential.user;
      try {
        await user.updateProfile({ displayName: name });
        console.log("updateProfile success");
        return user;
      } catch (error) {
        console.log("updateProfile failed", error);
        return user;
      }
    })
    .then((user) => {
      const userRef = firebase.firestore().collection('users').doc(user.uid)
      const userMap = {
        id: user.uid,
        name: user.displayName,
        email: user.email,
      }
      userRef.set(userMap, { merge: true }).then(() => {
        console.log("user successfully added");
      });
      return user
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      alert(errorMessage)
      return null
    });
}

function signIn(email, password) {
  return firebase.auth().signInWithEmailAndPassword(email, password)
}

function logout() {
  return firebase.auth().signOut()
}

function deleteAccount() {
  return firebase.auth().currentUser.delete()
}

async function forgotPassword(email) {
  return firebase.auth().sendPasswordResetEmail(email)
}

export { checkAuth, isAnonymous, getName, getEmail, signUp, forgotPassword, signIn, logout, deleteAccount, getUid, isRabbi };
