
import listQuery from '../firebase/ListFirestoreManager'
import singleQuery from '../firebase/SingleFirestoreManager'

function getSections(position) {
    return listQuery({
        database: 'sections',
        where: [
            {
                field: "position",
                operator: "==",
                value: position,
            },
        ]
    }).get()
}

function getTitle(titleBaseName) {
    return singleQuery({
        database: 'main_screen_titles',
        doc: titleBaseName,
    })
}

export { getSections, getTitle }