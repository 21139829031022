import React from "react";
import { Image, Dimensions } from 'react-native';
//import ImageZoom from 'react-native-image-pan-zoom';
import ResponsiveImage from "../components/ResponsiveImage";

const FullScreenImage = ({ route, navigation }) => {
    const { url } = route.params ?? {};
    return (
        // <ImageZoom cropWidth={Dimensions.get('window').width}
        //     cropHeight={Dimensions.get('window').height}
        //     imageWidth={200}
        //     imageHeight={200}>
        //     <ResponsiveImage source={{ uri: url }} />
        // </ImageZoom>
        <ResponsiveImage source={{ uri: url }} />
    )
}

export default FullScreenImage