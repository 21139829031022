import React, { useState, useEffect } from 'react';
import {
    StyleSheet,
    Text,
    View,
    useWindowDimensions,
    ActivityIndicator,
    ScrollView,
} from 'react-native';
import QuestionsList from './QuestionsList';
import ConversationsList from './ConversationsList';
import { getQuestions } from './ChatViewModel';
import theme from '../../utils/Colors'
import { Chip } from "react-native-paper";

export default function ChatScreen({ navigation }) {
    const window = useWindowDimensions();
    const [questionKey, setQuestionKey] = useState();
    console.log(questionKey)
    const [selectedTab, setSlectedTab] = useState(1);
    const [questions, isRabbi] = getQuestions(selectedTab)
    const isMobile = window.width < 495

    const onQuestionClick = (key) => {
        if (isMobile) {
            navigation.navigate("Conversation", { questionKey: key });
        } else {
            setQuestionKey(key)
        }
    }
    if (!questions) {
        return (
            <ActivityIndicator
                style={{ marginTop: 150 }}
                animating={true}
                color={theme.colors.primary}
            />
        );
    } else {
        return (
            //<TouchableWithoutFeedback onPress={() => Keyboard.dismiss()}>
            <View style={{ flex: 1, flexDirection: 'row' }}>
                <View style={{ flex: isMobile ? 1 : 0.6, paddingTop: 5 }}>
                    {isRabbi &&
                        <View style={{ marginLeft: 12, marginRight: 12, marginBottom: 5 }}>
                            <ScrollView horizontal showsHorizontalScrollIndicator={false} style={{}} >
                                <Text style={{height:32}}></Text>
                                <Chip icon="account-clock" style={{ marginEnd: 5 }} selected={selectedTab == 1} onPress={() => {
                                    setSlectedTab(1)
                                }}>לטיפול בהמשך</Chip>
                                <Chip icon="check" style={{ marginEnd: 5 }} selected={selectedTab == 2} onPress={() => {
                                    setSlectedTab(2)
                                }} >שאלות שנענו</Chip>
                                <Chip icon="archive" style={{ marginEnd: 5 }} selected={selectedTab == 3} onPress={() => {
                                    setSlectedTab(3)
                                }} >ארכיון</Chip>
                            </ScrollView>
                        </View>
                    }
                    <QuestionsList questions={questions} navigation={navigation} onItemPress={onQuestionClick} />
                </View>
                {!isMobile &&
                    <View style={{ flex: 1, flexDirection: 'row' }}>
                        <View style={styles.box} />
                        <ConversationsList questionKey={questionKey} navigation={navigation} />
                    </View>
                }
            </View>
        );
    }
}

const styles = StyleSheet.create({
    box: {
        height: '100%',
        width: 1,
        shadowColor: '#ccc',
        shadowOffset: { width: 1, height: 0 },
        shadowOpacity: 0.5,
        shadowRadius: 1,
        elevation: 1,
    }
});