const baseUrl = "https://www.sefaria.org/api/"
const calendarUrl = baseUrl + "calendars"
const texts = baseUrl + "texts/"

async function getDailyLimudSefaria(diaspora) {
    try {
        const response = await fetch(calendarUrl + '?diaspora=' + diaspora);
        const json = await response.json();
        return await json.calendar_items;
    } catch (error) {
        console.error(error);
    }
}

async function getTextSefaria(ref) {
    const response = await fetch(texts + ref);
    return await response.json();
}

export { getDailyLimudSefaria, getTextSefaria }