import React from "react";
import HomeScreen from "./src/screens/main/HomeScreen";
import DetailsScreen from "./src/screens/details/PostDetails";
import ChatScreen from "./src/screens/chat/ChatScreen";
import LoginScreen from "./src/screens/sign/LoginScreen";
import SelectedPostsByTag from "./src/screens/postsbytag/SelectedPostsByTag";
import ConversationsScreen from "./src/screens/chat/ConversationsScreen";
import QuestionScreen from "./src/screens/question/QuestionScreen";
import ZmanimScreen from "./src/screens/daily/zmanim/ZmanimScreen";
import PostBySubject from "./src/screens/subjects/PostBySubject";
import DailyLimudScreen from './src/screens/daily/limud/DailyLimudScreen'
import DailyLimudDetailScreen from "./src/screens/daily/limud/DailyLimudDetailScreen";
import AboutScreen from "./src/screens/menu/AboutScreen";
import FullScreenImage from './src/common/FullScreenImage'
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { View, StatusBar } from "react-native";
import { Appbar } from "react-native-paper";
import firebaseInit from "./src/firebase/firebaseInit";

import { I18nManager } from "react-native";
I18nManager.forceRTL(true);
const Stack = createNativeStackNavigator();
import theme from './src/utils/Colors'
import './src/utils/ScrollBar'

const linking = {
  prefixes: ["https://asktherabbi.com", "asktherabbi://"],
  config: {
    screens: {
      Home: "",
      Details: "/details",
      Chat: "/chat",
      Login: "/login",
      SelectedPostsByTag: "/selectedpostspertag",
      Conversation: "/conversation",
      Question: "/question",
      Zmanim: "/zmanim",
      Subject: "/subject",
      DailyLimud: "/limudyomi",
      DailyLimudDetail: "/limudyomidatail",
      About: "/about"
    },
  },
};

function StatusBarColored() {
  return (
    <StatusBar
      backgroundColor={theme.colors.primary}
      barStyle="light-content"
      style="auto"
    />
  );
}

function CustomNavigationBar({ navigation, back }) {
  return (
    <Appbar.Header>
      {/* {back ? <Appbar.BackAction onPress={navigation.goBack} /> : null} */}
      <Appbar.BackAction onPress={navigation.goBack} />
      <Appbar.Content title="פרטי ההלכה" />
    </Appbar.Header>
  );
}

export default function App() {
  firebaseInit();
  return (
    <NavigationContainer linking={linking}>
      <Stack.Navigator>
        <Stack.Screen
          name="Home"
          component={HomeScreen}
          options={{
            header: StatusBarColored,
            title: 'שאל את הרב | מאגר השו"ת | ארבע אמות של הלכה',
          }}
        />
        <Stack.Screen
          name="Details"
          component={DetailsScreen}
          options={{
            title: "",
            headerTintColor: theme.colors.textLight,
            headerStyle: { backgroundColor: theme.colors.primary },
            headerTitleAlign: "center",
            headerTitleStyle: {
              fontFamily: "AssistantBold",
            }
          }}
        />
        <Stack.Screen
          name="Chat"
          component={ChatScreen}
          options={{
            title: "השאלות שלי",
            headerTintColor: theme.colors.textLight,
            headerStyle: { backgroundColor: theme.colors.primary },
            headerTitleAlign: "center",
            headerTitleStyle: {
              fontFamily: "AssistantBold",
            }
          }}
        />
        <Stack.Screen
          name="Login"
          component={LoginScreen}
          options={{
            title: "החשבון שלי",
            headerTintColor: theme.colors.textLight,
            headerStyle: { backgroundColor: theme.colors.primary },
            headerTitleAlign: "center",
            headerTitleStyle: {
              fontFamily: "AssistantBold",
            }
          }}
        />
        <Stack.Screen
          name="SelectedPostsByTag"
          component={SelectedPostsByTag}
          options={{
            title: "הלכות",
            headerTintColor: theme.colors.textLight,
            headerStyle: { backgroundColor: theme.colors.primary },
            headerTitleAlign: "center",
            headerTitleStyle: {
              fontFamily: "AssistantBold",
            }
          }}
        />
        <Stack.Screen
          name="Conversation"
          component={ConversationsScreen}
          options={{
            title: "שאל את הרב",
            headerTintColor: theme.colors.textLight,
            headerStyle: { backgroundColor: theme.colors.primary },
            headerTitleAlign: "center",
            headerTitleStyle: {
              fontFamily: "AssistantBold",
            }
          }}
        />
        <Stack.Screen
          name="Question"
          component={QuestionScreen}
          options={{
            title: "שאלה חדשה",
            headerTintColor: theme.colors.textLight,
            headerStyle: { backgroundColor: theme.colors.primary },
            headerTitleAlign: "center",
            headerTitleStyle: {
              fontFamily: "AssistantBold",
            }
          }}
        />
        <Stack.Screen
          name="Zmanim"
          component={ZmanimScreen}
          options={{
            title: "זמני היום",
            headerTintColor: theme.colors.textLight,
            headerStyle: { backgroundColor: theme.colors.primary },
            headerTitleAlign: "center",
            headerTitleStyle: {
              fontFamily: "AssistantBold",
            }
          }}
        />
        <Stack.Screen
          name="Subject"
          component={PostBySubject}
          options={{
            title: "עיון במאגר",
            headerTintColor: theme.colors.textLight,
            headerStyle: { backgroundColor: theme.colors.primary },
            headerTitleAlign: "center",
            headerTitleStyle: {
              fontFamily: "AssistantBold",
            }
          }}
        />
        <Stack.Screen
          name="DailyLimud"
          component={DailyLimudScreen}
          options={{
            title: "לימוד יומי",
            headerTintColor: theme.colors.textLight,
            headerStyle: { backgroundColor: theme.colors.primary },
            headerTitleAlign: "center",
            headerTitleStyle: {
              fontFamily: "AssistantBold",
            }
          }}
        />
        <Stack.Screen
          name="DailyLimudDetail"
          component={DailyLimudDetailScreen}
          options={{
            title: "לימוד יומי",
            headerTintColor: theme.colors.textLight,
            headerStyle: { backgroundColor: theme.colors.primary },
            headerTitleAlign: "center",
            headerTitleStyle: {
              fontFamily: "AssistantBold",
            }
          }}
        />
        <Stack.Screen
          name="About"
          component={AboutScreen}
          options={{
            title: "אודות האתר",
            headerTintColor: theme.colors.textLight,
            headerStyle: { backgroundColor: theme.colors.primary },
            headerTitleAlign: "center",
            headerTitleStyle: {
              fontFamily: "AssistantBold",
            }
          }}
        />
        <Stack.Screen
          name="FullScreenImage"
          component={FullScreenImage}
          options={{
            header: StatusBarColored,
            title: "",
          }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}
