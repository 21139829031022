import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  useWindowDimensions,
  Linking,
  Image,
  Platform,
} from "react-native";
import {
  FAB,
  Chip,
  Searchbar,
  Appbar,
  Button,
  Menu,
  Divider,
  Provider,
} from "react-native-paper";
import * as WebBrowser from 'expo-web-browser';
import { ScrollView, SectionList } from "react-native";
import algoliasearch from "algoliasearch";
const client = algoliasearch("46LA9JOG1V", "40ed672f0070bf0387b8dadd347acf7f");
const algoliaIndex = client.initIndex("posts");
import fetchData from './PostsViewModel'
import LoadingList from "../../components/LoadingList";
import SectionListItem from '../../components/SectionListItem'
import { isAnonymous } from "../../firebase/UserManagement";
import ConnectDialog from "../../common/ConnectDialog";
import theme from '../../utils/Colors'
import stopWords from "../../algolia/StopWords";
const queryParams = {
  removeWordsIfNoResults: 'allOptional',
  ignorePlurals: true,
  hitsPerPage: 50,
  attributesToRetrieve: ["title", "question", "answer", "image_urls", "opened_by_user", "thumbnail"],
}

export default function PostScreen({ navigation }) {
  console.log("PostScreen", new Date())
  const window = useWindowDimensions();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [searching, setSearching] = useState(false);
  const onChangeSearch = (query) => setSearchQuery(query);
  const [selectedTab, setSlectedTab] = useState(1);

  const [visible, setVisible] = useState(false);
  const showDialog = () => setVisible(true);
  const hideDialog = () => setVisible(false);

  const [visibleMenu, setVisibleMenu] = useState(false);
  const openMenu = () => setVisibleMenu(true);
  const closeMenu = () => setVisibleMenu(false);

  useEffect(() => {
    if (searchQuery.length > 1) {
      setSearching(true)
      const delayDebounceFn = setTimeout(() => {
        const splitedQuery = searchQuery.trim().split(' ')
        const wordsForQuery = []
        splitedQuery.forEach((word) => {
          if (!stopWords.includes(word) && word !== '') {
            wordsForQuery.push(word)
          }
        })
        if (wordsForQuery.length === 0) {
          setSearching(false)
          return
        }
        const thqQuery = wordsForQuery.join(' ')
        console.log(thqQuery)
        algoliaIndex.search(thqQuery, queryParams).then((res) => {
          setSearching(false)
          console.log(res);
          const items = res.hits.map((hit) => {
            return {
              subject: hit.subject,
              title: hit._highlightResult.title.value,
              question: hit._highlightResult.question.value,
              answer: hit.answer,
              image_urls: hit.image_urls,
              key: hit.objectID,
            };
          });
          if (items.length === 0) {
            setSearchResult([]);
          } else {
            setSearchResult(items);
          }
        });
      }, 750);
      return () => clearTimeout(delayDebounceFn);
    } else {
      setSearchResult([]);
    }
  }, [searchQuery]);

  const { postsFirebase, loading } = fetchData(selectedTab);
  let [posts, dataCount] = postsFirebase ?? []
  if (searchResult.length > 0) {
    posts = searchResult
  }
  let num = 1;
  if (window.width > 1140) {
    num = 3;
  } else if (window.width > 880) {
    num = 2;
  }
  let searchPlaceHolder
  if (num > 1) {
    if (dataCount) {
      searchPlaceHolder = 'חפש פסקים ותשובות | ' + dataCount + ' שו"ת במאגר'
    } else {
      searchPlaceHolder = 'ארבע אמות של הלכה'
    }
  } else {
    searchPlaceHolder = 'חפש במאגר ההלכות'
  }
  if (posts && posts.length === 0) {
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <Text style={styles.item}>לא הצלחנו לטעון את ההלכות, בדקו את החיבור לאינטרנט.</Text>
      </View>
    )
  } else {
    return (
      <Provider>
        <View style={{ flex: 1 }}>
          <View
            style={{
              marginLeft: 10,
              marginRight: 10,
              paddingLeft: num === 1 ? 0 : 16,
              paddingRight: num === 1 ? 0 : 16,
              flexDirection: 'row',
            }}
          >
            <View style={{ justifyContent: 'flex-end', flexDirection: 'row', marginTop: 4 }}>
              <Menu
                visible={visibleMenu}
                onDismiss={closeMenu}
                anchor={<Appbar.Action icon="dots-vertical" onPress={openMenu} color='black' />}>
                <Menu.Item titleStyle={{ fontFamily: 'Assistant' }} icon={'face-agent'} onPress={() => {
                  closeMenu() 
                  Linking.openURL('mailto:maagarhashut@gmail.com?subject=תמיכה ופניה בקשר לאתר מאגר השו"ת') 
                }
                } title="תמיכה ופניות" />
                {/* <Menu.Item icon={'share-variant'} onPress={() => { }} title="שיתוף " /> */}
                {/* <Divider /> */}
                <Menu.Item titleStyle={{ fontFamily: 'Assistant' }} icon="format-list-text" onPress={() => {
                  closeMenu()
                  if (Platform.OS == 'ios') {
                    let aboutUrl = 'https://firebasestorage.googleapis.com/v0/b/siddur-klilat-yofi.appspot.com/o/about.pdf?alt=media&token=6d10ac81-85fb-4d69-8894-07e16e84f45c'
                    WebBrowser.openBrowserAsync(aboutUrl);
                  } else {
                    navigation.navigate("About")
                  }
                }} title="אודות" />
              </Menu>
              <Appbar.Action icon="account" onPress={() => navigation.navigate("Login")} color='#660000' style={{ backgroundColor: 'rgba(100, 0, 0, .4)' }} />

              {/* <Appbar.Action icon="calendar" onPress={() => alert("Pressed")} color='black' /> */}
              {/* <Avatar.Text size={32} label="יל" style={{ marginRight: 10 }} /> */}
            </View>
            {num > 1 &&
              <Image style={{ width: 178, height: 83.5, marginRight: 8, marginLeft: 8, marginTop: 8 }} source={require('../../assets/images/ask_rav_logo.png')} />
            }
            <View style={{ flex: 1, flexWrap: 'wrap' }}>
              <View style={{ flex: 1, minHeight: num == 1 ? 68 : 56, alignItems: 'left', width: '100%' }}>
                <Searchbar
                  inputStyle={{ fontFamily: 'Assistant' }}
                  style={{ marginTop: 7, height: 40, boxShadow: "#00000000", shadowOffset: 0, width: '100%' }}
                  placeholder={searchPlaceHolder}
                  onChangeText={onChangeSearch}
                  value={searchQuery}
                  inputStyle={{ fontFamily: dataCount || num == 1 ? 'Assistant' : 'Suez' }}
                />
                {num == 1 && dataCount && <Text style={{ marginTop: 2, fontFamily: 'Assistant' }}>המאגר מכיל כעת {dataCount} שאלות</Text>}

              </View>
              {num > 1 &&
                <View style={{ justifyContent: 'center', flexDirection: 'row' }}>
                  <Button icon="book-open-variant" labelStyle={{ fontFamily: 'AssistantMedium' }} style={{ backgroundColor: theme.colors.primary, marginEnd: 10 }} mode="contained" onPress={() => {
                    if (isAnonymous()) {
                      showDialog()
                    } else {
                      navigation.navigate("Question")
                    }
                  }}>
                    שאל את הרב
                  </Button>
                  <Button icon="message-bulleted" labelStyle={{ fontFamily: 'AssistantMedium' }} style={{ backgroundColor: theme.colors.primary }} mode="contained"
                    onPress={() => {
                      if (isAnonymous()) {
                        showDialog()
                      } else {
                        navigation.navigate("Chat")
                      }
                    }}>
                    השאלות שלי
                  </Button>
                </View>
              }
            </View>

          </View>

          <View style={{ flex: 1, paddingLeft: num == 1 ? 0 : 16, paddingRight: num == 1 ? 0 : 16 }}>
            {searchResult.length === 0 &&
              <View style={{ marginLeft: 12, marginRight: 12, marginBottom: 5 }}>
                <ScrollView horizontal showsHorizontalScrollIndicator={false} style={{ marginTop: 16, }} >
                  <Text style={{height:32}}></Text>
                  <Chip icon="star" textStyle={{ fontFamily: 'Assistant' }} style={{ marginEnd: 5 }} selected={selectedTab == 1} onPress={() => {
                    setSlectedTab(1)
                  }}>בשבילך</Chip>
                  {/* <Chip icon="bookmark" style={{ marginEnd: 5 }} selected={selectedTab == 2} onPress={() => {
                  setSlectedTab(2)
                }}>הלכה יומית</Chip> */}
                  <Chip icon="lightbulb" textStyle={{ fontFamily: 'Assistant' }} style={{ marginEnd: 5 }} selected={selectedTab == 3} onPress={() => {
                    setSlectedTab(3)
                  }} >מחשבה יהודית</Chip>
                  <Chip icon="book-open-variant" textStyle={{ fontFamily: 'Assistant' }} style={{ marginEnd: 5 }} selected={selectedTab == 4} onPress={() => {
                    setSlectedTab(4)
                  }} >פרשת השבוע</Chip>
                  <Chip icon="clock-time-eight-outline" textStyle={{ fontFamily: 'Assistant' }} style={{ marginEnd: 6 }} onPress={() => {
                    navigation.navigate("Zmanim")
                  }} >זמני היום</Chip>
                  <Chip icon="calendar" textStyle={{ fontFamily: 'Assistant' }} style={{ marginEnd: 7 }} selected={selectedTab == 6} onPress={() => {
                    navigation.navigate("DailyLimud")
                  }} >לוח לימוד יומי</Chip>
                </ScrollView>
              </View>
            }
            {/* <ProgressBar color={Colors.red800} indeterminate={searching} /> */}
            {/* <Text>{`Window Dimensions: height - ${window.height}, width - ${window.width}`}</Text> */}
            {posts === undefined && <LoadingList num={num} />}
            {searchResult.length > 0 &&
              <View style={{ flex: 1, marginTop: num > 1 ? 10 : 0 }}>
                <SectionListItem posts={searchResult} num={num} navigation={navigation} horizontal={false} />
              </View>
            }
            {!searching && searchQuery.length > 1 && searchResult.length === 0 &&
              <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <Text style={styles.item}> לא נמצאה תשובה לשאלתך, תוכלו לשלוח שאלה לרב על ידי לחיצה על כפתור 'שאל את הרב'</Text>
              </View>
            }
            {searchResult.length === 0 && searchQuery.length <= 1 && posts &&
              <SectionList
                overScrollMode='never'
                style={styles.container}
                sections={posts}
                keyExtractor={(item, index) => item + index}
                renderItem={({ item }) => <SectionListItem posts={item} num={num} navigation={navigation} horizontal={true} />}
                renderSectionHeader={({ section: { title, tag } }) => (
                  <View style={{ flexDirection: 'row', }}>
                    <Text style={styles.header}>{title}</Text>
                    <Button labelStyle={{ fontFamily: 'AssistantMedium' }} contentStyle={{ flexDirection: 'row-reverse', fontSize: 15 }} style={{ alignSelf: 'flex-end' }} color='#004e5f'
                      icon={{ source: "play", direction: 'rtl' }} mode="text" onPress={() => navigation.navigate("SelectedPostsByTag", { tag: tag })}>
                      ראה עוד
                    </Button>
                  </View>
                )}
              />
            }
            {loading && posts &&
              <View style={{
                position: 'absolute', height: '80%', width: '95%', backgroundColor: 'rgba(255, 255, 255, .5)'
              }} />
            }
            {num === 1 &&
              <FAB
                style={styles.fab}
                icon="message-bulleted"
                onPress={() => {
                  if (isAnonymous()) {
                    showDialog()
                  } else {
                    navigation.navigate("Chat")
                  }
                }}
              />
            }
            <ConnectDialog visible={visible} hideDialog={hideDialog} navigation={navigation} />

          </View>
        </View>
      </Provider>
    );
  }

}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
  },
  list: {
    flexGrow: 1,
    marginTop: 8,
  },
  item: {
    fontFamily: 'Assistant',
    width: 300,
    marginTop: 24,
    padding: 30,
    backgroundColor: "#eeeeee",
    textAlign: "center",
    fontSize: 20,
    marginHorizontal: 12,
  },
  indicator: {
    flex: 1,
    color: "green",
  },
  buttonContainer: {
    justifyContent: "space-around",
  },
  fab: {
    position: "absolute",
    margin: 16,
    right: 0,
    bottom: 0,
    backgroundColor: theme.colors.accent,
  },
  header: {
    fontFamily: 'Suez',
    fontSize: 20,
    color: '#666666',
    height: 30,
    paddingLeft: 16,
    paddingRight: 16,
    marginTop: 10,
    marginBottom: 2,
  },
});
