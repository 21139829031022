import firebase from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyCNq-NmICZXcO4JCSPE-UAMp2U34IP_Onc",
  authDomain: "asktherabbi-6e4ea.firebaseapp.com",
  databaseURL: "https://asktherabbi-6e4ea.firebaseio.com",
  projectId: "asktherabbi-6e4ea",
  storageBucket: "asktherabbi-6e4ea.appspot.com",
  messagingSenderId: "645335197981",
  appId: "1:645335197981:web:d68dab30491ebe8a3619f4",
};
const firebaseInit = () => {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
    firebase.firestore().settings({ experimentalForceLongPolling: true, merge: true });
  }
};
export default firebaseInit;
