import React, { useState, useEffect, createRef, useRef } from 'react';
import {
    StyleSheet,
    Text,
    View,
    TextInput,
    FlatList,
    ImageBackground,
    ActivityIndicator,
} from 'react-native';
import { getUid } from '../../firebase/UserManagement'
import { Appbar, Menu, Provider } from 'react-native-paper';
import { isRabbi } from '../../firebase/UserManagement'
import ChatItem from './ChatItem';
import { sendImage, sendChat, getConversation, getQuestion } from './ConversationViewModel'
import theme from '../../utils/Colors'
import EditChatDialog from './EditChatDialog';
import DeleteChatDialog from './DeleteChatDialog';
import * as ImagePicker from 'expo-image-picker';


const ConversationsList = ({ questionKey, navigation }) => {
    const [conversation, question] = getConversation(questionKey)
    const [inputMessage, setInputMessage] = useState('');
    const Uid = getUid()
    const myTextInput = createRef()
    const conversationFlatList = createRef()
    const questionKeyRef = useRef()
    const [image, setImage] = useState(null);

    const pickImage = async () => {
        // No permissions request is necessary for launching the image library
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            allowsEditing: true,
          });

          console.log(result);

          if (!result.cancelled) {
            setImage(result.uri);
            sendImage(questionKey,result)
          }
    };

    useEffect(() => {
        let isFirstDisplay = false
        if (questionKeyRef.current != questionKey) {
            questionKeyRef.current = questionKey
            isFirstDisplay = true
        }
        console.log("isFirstDisplay", isFirstDisplay)
        if (!isFirstDisplay) {
            const timeout = setTimeout(() => {
                if (conversationFlatList.current && conversation && conversation.length > 0) {
                    conversationFlatList.current.scrollToEnd({ animated: true })
                }
            }, 200);
            return () => clearTimeout(timeout)
        } else {
            setInputMessage('');
            if (myTextInput.current) {
                myTextInput.current.clear()
            }
        }
    }, [conversation])
    function sendMessage() {
        if (inputMessage === '') {
            return
        }
        sendChat(questionKey, inputMessage, 0)
        setInputMessage('');
        myTextInput.current.clear()
    }
    const userIds = []
    let totalUsers = 0
    if (question) {
        Object.keys(question.users_ids).forEach(function (key, index) {
            if (key === Uid) {
                userIds.unshift("את/ה")
            } else {
                userIds.push(question.users_ids[key])
                totalUsers = totalUsers + 1
            }
        });
    }

    const [visibleEdit, setVisibleEdit] = useState({});
    const openEdit = (item) => setVisibleEdit(item);
    const closeEdit = () => setVisibleEdit({});
    const [visibleDelete, setVisibleDelete] = useState({});
    const openDelete = (item) => setVisibleDelete(item);
    const closeDelete = () => setVisibleDelete({});
    const [visibleMenu, setVisibleMenu] = useState({});
    const openMenu = (item) => setVisibleMenu(item);
    const closeMenu = () => setVisibleMenu({});
    const onLongPress = (item) => {
        if (isRabbi()) {
            openMenu(item)
        }
    }
    return (
        <Provider>
            <View style={styles.container}>
                {question &&
                    <View style={{ backgroundColor: '#ededed', height: 48, justifyContent: 'center', paddingStart: 16 }}>
                        <Text style={{ fontFamily: 'AssistantMedium', fontSize: 14, color: '#222222' }}>{question.title}</Text>
                        <Text style={{ fontFamily: 'Assistant', color: '#444444', fontSize: 11}}>{userIds.join(', ')}</Text>
                    </View>
                }

                <ImageBackground
                    style={{ flex: 1, backgroundColor: 'rgba(0, 78, 95, .1)' }}
                    imageStyle={{ resizeMode: 'repeat', opacity: 1 }}
                    source={require('../../assets/images/shutterstock_116579431.jpg')} >
                    {((!question || !conversation) && questionKey) &&
                        <ActivityIndicator
                            style={{ marginTop: 150 }}
                            animating={true}
                            color={theme.colors.primary}
                        />
                    }
                    <Menu
                        visible={visibleMenu.message}
                        onDismiss={closeMenu}
                        style={{ left: 8, top: 60, right: undefined }}
                        anchor={<View style={{ width: 1, height: 1, }} />}>
                        <Menu.Item icon="pencil" onPress={() => {
                            openEdit(visibleMenu)
                            closeMenu()
                        }} title="עריכה" />
                        <Menu.Item icon='delete' onPress={() => {
                            openDelete(visibleMenu)
                            closeMenu()
                        }
                        } title="מחיקה" />
                    </Menu>

                    {conversation && question &&
                        <View style={styles.container}>
                            <FlatList
                                ref={conversationFlatList}
                                data={conversation}
                                keyExtractor={(item) => item.key}
                                renderItem={({ item }) => (
                                    <ChatItem item={item} Uid={Uid} navigation={navigation} showName={totalUsers > 1} onLongPress={onLongPress} />
                                )}
                            // onContentSizeChange={() => conversationFlatList.current.scrollToEnd()}
                            />
                            <View style={{ paddingTop: 10 }}>
                                <View style={styles.messageInputView}>
                                    <TextInput
                                        ref={myTextInput}
                                        style={styles.messageInput}
                                        placeholder='הקלד הודעה'
                                        onChangeText={(text) => setInputMessage(text)}
                                        onSubmitEditing={() => { sendMessage() }}
                                    />
                                    <Appbar.Action icon={{ source: "camera", direction: 'rtl' }} onPress={() => pickImage()} color='#333333' style={{ alignSelf: 'center', marginEnd: 10 }} />

                                    <Appbar.Action icon={{ source: "send", direction: 'rtl' }} onPress={() => sendMessage()} color='#333333' style={{ alignSelf: 'center', marginEnd: 10 }} />
                                </View>
                            </View>
                        </View>
                    }
                </ImageBackground>
                <EditChatDialog hideDialog={closeEdit} item={visibleEdit} />
                <DeleteChatDialog hideDialog={closeDelete} item={visibleDelete} />
            </View>
        </Provider>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    messageInputView: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#ededed',
    },
    messageInput: {
        fontFamily: 'Assistant',
        textAlign: 'left',
        height: 40,
        flex: 1,
        paddingHorizontal: 10,
        backgroundColor: 'white',
        borderRadius: 21,
        alignSelf: 'center',
        marginStart: 16,
        marginEnd: 5,
    },
    messageSendView: {
        paddingHorizontal: 10,
        justifyContent: 'center',
    },
});

export default ConversationsList