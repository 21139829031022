import singleQuery from "../../firebase/SingleFirestoreManager";
import { checkAuth, getUid } from '../../firebase/UserManagement';
import { useState, useEffect } from "react";
//import listQuery from "../../firebase/ListFirestoreManager";
//import firebase from "firebase/app";
//import "firebase/firestore";

export default function getAllSubject() {

    const currentUser = checkAuth();
    const [subjects, setSubjects] = useState();

    useEffect(() => {
        //console.log('AllSubject', 1, new Date())
        if (currentUser && !subjects) {
            async function getSubjects() {
                // const resultRef = await listQuery({
                //     database: 'posts'
                // }).get()
                // const result = resultRef.docs.map((doc) => ({ key: doc.id, ...doc.data() }))
                //     .filter(post => post.searchable)
                // const map = {}
                // for (const post of result) {
                //     if (post.subject && post.subject.length > 0) {
                //         if (!map[post.subject]) {
                //             map[post.subject] = 0
                //         }
                //         map[post.subject] = map[post.subject] + 1
                //     }
                // }
                // const searchable = firebase.firestore().collection('subjects').doc('searchable')
                // const data = {}
                // data['subjects'] = map
                // searchable.set(data).then(() => {
                //     console.log("statistic successfully updated");
                // });
                const subjectsref = await singleQuery({
                    database: "subjects",
                    doc: "searchable"
                })
                const data = subjectsref.data()
                const res = []
                Object.keys(data.subjects).forEach((key, index) => {
                    if (data.subjects[key] > 0) {
                        res.push(key)
                    }
                })
                res.sort()
                setSubjects(res)
            }
            setTimeout(() => getSubjects(), 2000);
        }
    })
    return subjects
}