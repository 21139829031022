
import React, { useState, useEffect, createRef } from "react";
import getZmanim from "./ZmanimViewModel";
import {
    StyleSheet,
    Text,
    View,
    useWindowDimensions,
    ActivityIndicator,
    Image,
    SafeAreaView,
    ScrollView,
    FlatList
} from "react-native";
import { Card } from "react-native-paper";

const ZmanimScreen = () => {

    const zmanim = getZmanim()
    if (zmanim) {
        return <FlatList
            style={styles.list}
            keyExtractor={item => item.key}
            data={zmanim}
            renderItem={({ item }) => (
                <Card style={{ flex: 1, marginLeft: 12, marginRight: 12, marginBottom: 6 }}>
                    <Card.Title title={item.value + " " + item.name} />
                </Card>
            )}
        />
    } else {
        return <View />
    }
}

const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
    },
    list: {
        flexGrow: 1,
        marginTop: 8,
    },

})
export default ZmanimScreen