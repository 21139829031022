import React from 'react';
import {
    StyleSheet,
    SafeAreaView,
    useWindowDimensions,
    ActivityIndicator,
    View,
    Text
} from 'react-native';
import SectionListItem from '../../components/SectionListItem'
import getPostsBySubject from './PostsBySubjectViewModel';
import theme from '../../utils/Colors'

const PostBySubject = ({ route, navigation }) => {
    const window = useWindowDimensions();
    const { subject } = route.params ?? {};
    console.log(subject)
    const [posts, notExist] = getPostsBySubject(subject)
    let num = 1;
    if (window.width > 1140) {
        num = 4;
    } else if (window.width > 880) {
        num = 3;
    } else if (window.width > 495) {
        num = 2;
    }
    if (posts) {
        return (
            <SafeAreaView style={{ flex: 1, paddingTop: 8 }}>
                <SectionListItem posts={posts} num={num} navigation={navigation} horizontal={false} />
            </SafeAreaView>
        )
    } else if (notExist) {
        return <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
            <Text style={styles.title}>404 | הדף לא קיים</Text>
        </View>
    } else {
        return <ActivityIndicator
            style={{ marginTop: 150 }}
            animating={true}
            color={theme.colors.primary}
        />
    }
}

const styles = StyleSheet.create({
    title: {
        color: "#7A7A7A",
        fontSize: 30,
        fontWeight: "700",
        marginTop: 15,
    }
});

export default PostBySubject