import React from "react";
import {
    StyleSheet,
    FlatList,
    View,
} from "react-native";
import CardItem from "../components/CardItem";

const SectionListItem = ({ posts, num, navigation, horizontal }) => {
    return (
        <View style={{ flex: 1 }}>
            <FlatList
                style={styles.list}
                horizontal={horizontal}
                numColumns={horizontal ? 1 : num}
                keyExtractor={(item) => item.key}
                key={horizontal ? undefined : num}
                data={posts}
                renderItem={({ item }) => (
                    <CardItem
                        onItemPress={() => {
                            navigation.navigate("Details", { itemId: item.key });
                        }}
                        item={item}
                        num={num}
                        horizontal={horizontal}
                        numberOfLines={2}
                    />
                )}
            />
            {horizontal &&
                <View style={{ height: 1, width: '100%', backgroundColor: '#cccccc' }} />
            }
        </View>
    )
}

const styles = StyleSheet.create({
    list: {
        flexGrow: 1,
        // marginTop: 8,
    }
});

export default SectionListItem