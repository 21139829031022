import React from "react";
import RenderHtml, { defaultSystemFonts } from 'react-native-render-html';
const systemFonts = [...defaultSystemFonts, 'Assistant'];
import {
    ScrollView,
    useWindowDimensions
} from "react-native";

const AboutScreen = () => {
    const contentWidth = useWindowDimensions().width;

    const CONTENT = require('../../assets/maagar_about.html')
    console.log(CONTENT)
    return <ScrollView style={{ flex: 1, paddingHorizontal: 16 }}>
        <RenderHtml source={{ html: CONTENT }} contentWidth={contentWidth} systemFonts={systemFonts}
        />
    </ScrollView >

}

export default AboutScreen