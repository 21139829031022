
import React, { useState, useEffect } from "react";
import {
    StyleSheet,
    Text,
    View,
    SafeAreaView,
    useWindowDimensions,
    ScrollView
} from "react-native";
import {
    FAB,
    Chip,
} from "react-native-paper";
import getAllSubject from "./AllSubjectViewModel"

const AllSubject = ({ navigation }) => {
    const subjects = getAllSubject()
    if (subjects) {
        return (
            <View style={{ flex: 1, backgroundColor: '#dddddd' }}>
                <Text style={styles.header}>עיון במאגר</Text>
                <ScrollView>
                    <View style={{ flexWrap: 'wrap', paddingStart: 10, paddingEnd: 5, flexDirection: 'row', }} >
                        {
                            subjects.map((el, i) => (
                                <Chip key={i} style={{ marginEnd: 5, marginTop: 5, justifyContent: 'center', flexGrow: 1 }} onPress={() => { navigation.navigate("Subject", { subject: el }) }}>{el}</Chip>
                            ))
                        }
                    </View>
                </ScrollView>
            </View>
        )
    } else {
        return <View style={{ flex: 1, backgroundColor: '#dddddd' }} />
    }

}
const styles = StyleSheet.create({
    header: {
        fontFamily: 'Suez',
        fontSize: 20,
        color: '#666666',
        height: 30,
        paddingLeft: 16,
        paddingRight: 16,
        marginTop: 12,
        marginBottom: 7,
        alignSelf: 'center'
    },
});

export default AllSubject