
import listQuery from "../../firebase/ListFirestoreManager";
import { checkAuth, getUid, isRabbi } from '../../firebase/UserManagement';
import { useState, useEffect } from "react";

function getQuestions(selectedTab) {
    const currentUser = checkAuth();
    const [questions, setQuestions] = useState([]);
    useEffect(() => {
        if (currentUser) {
            console.log("getQuestions")
            const rabbi = isRabbi()
            let query;
            switch (selectedTab) {
                case 1:
                    if (rabbi) {
                        query = listQuery({
                            database: "questions",
                            orderByField: 'updated_at',
                            direction: 'desc',
                            limit: 120,
                            where: [
                                {
                                    field: 'answered',
                                    operator: '==',
                                    value: false
                                }
                            ],
                        })
                    } else {
                        query = listQuery({
                            database: "questions",
                            orderByField: 'updated_at',
                            direction: 'desc',
                            where: [
                                {
                                    field: 'uid',
                                    operator: '==',
                                    value: getUid()
                                }
                            ],
                        })
                    }
                    break
                case 2:
                    if (rabbi) {
                        query = listQuery({
                            database: "questions",
                            orderByField: 'updated_at',
                            direction: 'desc',
                            limit: 120,
                            where: [
                                {
                                    field: 'answered',
                                    operator: '==',
                                    value: true
                                },
                                {
                                    field: 'status',
                                    operator: '==',
                                    value: 1
                                }
                            ],
                        })
                    }
                    break
                case 3:
                    if (rabbi) {
                        query = listQuery({
                            database: "questions",
                            orderByField: 'updated_at',
                            direction: 'desc',
                            limit: 120,
                            where: [
                                {
                                    field: 'answered',
                                    operator: '==',
                                    value: true
                                },
                                {
                                    field: 'status',
                                    operator: '==',
                                    value: 2
                                }
                            ],
                        })
                    }
                    break
            }
            if (query) {
                query.onSnapshot((querySnapshot) => {
                    const res = [];
                    querySnapshot.forEach((doc) => {
                        res.push({ key: doc.id, ...doc.data() });
                    });
                    console.log("Query questions success");
                    console.log(res.length);
                    setQuestions([res, rabbi])
                });
            }
        }
    }, [currentUser, selectedTab])
    return questions
}

export { getQuestions }