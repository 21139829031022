import React, { useState, useEffect } from 'react';
import { isAnonymous, getUid, getEmail, getName } from '../../firebase/UserManagement';
import firebase from "firebase/app";
import "firebase/firestore";

function sendQuestion(subject, question, urgentQuestion, checkedSource) {
    return new Promise(response => {
        if (!isAnonymous()) {
            const uid = getUid()
            const name = getName()
            const users_ids = {}
            users_ids[uid] = name
            const users_id_list = []
            users_id_list.push(uid)
            const questionObj = {
                title: subject,
                uid: uid,
                urgent_question: urgentQuestion,
                question: question,
                name: name,
                email: getEmail(),
                answered: false,
                updated_at: firebase.firestore.FieldValue.serverTimestamp(),
                timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                rabbi_status: {},
                new_messages: {},
                users_ids: users_ids,
                users_id_list: users_id_list,
                locked_discussion: false,
                status: 0,
                continues_question: false,
                picked_to_post: false,
                handler_name: null,
                handler_name: null,
                new_messages: {}
            }
            const questionRef = firebase.firestore().collection('questions').doc()
            questionRef.set(questionObj).then(() => {
                console.log("questionPart successfully updated");
                response(1)
            });
            const conversationSubject = {
                message: subject,
                message_type: 0,
                name: name,
                type: 0,
                uid: uid,
                timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            }
            questionRef.collection('conversations').add(conversationSubject).then((docRef) => {
                console.log("Document written with ID: ", docRef.id);
            }).catch((error) => {
                console.error("Error adding document: ", error);
            });
            const conversationQuestion = {
                message: question,
                message_type: 0,
                name: name,
                type: 0,
                uid: uid,
                timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            }
            questionRef.collection('conversations').add(conversationQuestion).then((docRef) => {
                console.log("Document written with ID: ", docRef.id);
            }).catch((error) => {
                console.error("Error adding document: ", error);
            });
            if (checkedSource) {
                const conversationSource = {
                    message: "דרוש מקור",
                    message_type: 0,
                    name: name,
                    type: 0,
                    uid: uid,
                    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                }
                questionRef.collection('conversations').add(conversationSource).then((docRef) => {
                    console.log("Document written with ID: ", docRef.id);
                }).catch((error) => {
                    console.error("Error adding document: ", error);
                });
            }
        } else {
            response(-1)
        }
    })
}



export { sendQuestion }