import React, { useState, useEffect } from 'react';
import {
    StyleSheet,
    Text,
    View,
    Image,
    TouchableWithoutFeedback,
    Linking,
    Platform,
} from 'react-native';
import * as WebBrowser from 'expo-web-browser';

import { spanBoldAndLink } from '../../utils/TextAppearanceUtils'
import getColorForText from '../../utils/ColorGenerator';
function formatTime(timestamp) {
    const date = timestamp ? new Date(timestamp.toDate()) : new Date()
    return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
    // return date.toLocaleDateString('en-US', {
    //     day: '2-digit',
    //     month: '2-digit',
    //     year: 'numeric',
    // })
}

function getItemViewType(item, userId) {
    if (item.uid !== userId) {
        return item.message_type + 2;
    }
    return item.message_type;
}

const ChatItem = ({ item, Uid, navigation, showName, onLongPress }) => (
    <TouchableWithoutFeedback onPress={() => { onLongPress(item) }}>
        <View style={{ marginTop: 6 }}>
            <View
                style={{
                    maxWidth: '80%',
                    backgroundColor: item.uid === Uid ? '#004e5f' : '#ffffff',
                    alignSelf:
                        item.uid === Uid
                            ? 'flex-end'
                            : 'flex-start',
                    marginHorizontal: 10,
                    padding: 10,
                    borderRadius: 8,
                    borderTopLeftRadius:
                        item.uid === Uid ? 8 : 0,
                    borderTopRightRadius:
                        item.uid === Uid ? 0 : 8,
                }}
            >
                {showName && getItemViewType(item, Uid) > 1 &&
                    <Text style={{ fontFamily: 'Assistant', color: getColorForText(item.name), fontSize: 13 }}>{item.name}</Text>
                }
                {getItemViewType(item, Uid) % 2 == 0 &&
                    <Text style={{
                        color: item.uid === Uid ? '#fff' : '#333333',
                        fontSize: 16,
                    }}>{spanBoldAndLink(item.message, (postKey) => navigation.navigate("Details", { itemId: postKey }))}</Text>
                }
                {getItemViewType(item, Uid) % 2 != 0 &&
                    <TouchableWithoutFeedback onPress={() => WebBrowser.openBrowserAsync(item.message)}>
                        <Image style={{ width: 200, height: 200 }} source={{ uri: item.message }} />
                    </TouchableWithoutFeedback>
                }
                <Text style={{
                    color: item.uid === Uid ? 'rgba(255, 255, 255, .5)' : 'rgba(0,0,0,0.5)',
                    fontSize: 14,
                    fontFamily: 'Assistant',
                    alignSelf: 'flex-end',
                }}>
                    {formatTime(item.timestamp)}
                </Text>
            </View>
        </View>
    </TouchableWithoutFeedback>
)

export default ChatItem