import React, { useState, useEffect } from 'react';
import {
    StyleSheet,
    Text,
    View,
    StatusBar,
    TextInput,
    TouchableOpacity,
    TouchableWithoutFeedback,
    Keyboard,
    Dimensions,
    SafeAreaView,
    useWindowDimensions,
} from 'react-native';
import { Paragraph, Dialog, Portal, Provider, Button, Checkbox } from "react-native-paper"
import { Icon } from 'react-native-elements';
import { sendQuestion } from './QuestionViewModel';
import { checkAuth } from '../../firebase/UserManagement';
import theme from '../../utils/Colors'

export default function QuestionScreen({ navigation }) {
    checkAuth();

    const window = useWindowDimensions();
    let isMobile = window.width <= 1100;

    // question sent
    const [visible, setVisible] = useState(false);
    const showDialog = () => setVisible(true);
    const hideDialog = () => setVisible(false);
    const continueDialog = () => {
        hideDialog()
        navigation.goBack(null)
        navigation.navigate("Chat")
    }

    const [subject, setSubject] = useState("");
    const [question, setQuestion] = useState("");
    const [isUrgent, setIsUrgent] = useState(0);

    const [checkedSource, setCheckedSource] = useState(false);

    const submitQuestion = () => {
        if (subject.length === 0 || question.length === 0) {
            return
        }
        sendQuestion(subject, question, isUrgent, checkedSource).then(response => {
            if (response === 1) {
                showDialog()
            }
        })
    }

    return (
        <SafeAreaView style={styles.container}>
            <View style={styles.authBox}>
                <TextInput
                    style={styles.input}
                    underlineColorAndroid="transparent"
                    placeholder='נושא'
                    placeholderTextColor='#9a9a9c'
                    returnKeyType='next'
                    onChangeText={(text) => setSubject(text)}
                />
                <TextInput
                    style={styles.textArea}
                    underlineColorAndroid="transparent"
                    placeholder="שאלה"
                    placeholderTextColor="#9a9a9c"
                    numberOfLines={10}
                    multiline={true}
                    onChangeText={(text) => setQuestion(text)}
                />
                <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 4 }}>
                    <Checkbox
                        color={theme.colors.primary}
                        status={checkedSource ? 'checked' : 'unchecked'}
                        onPress={() => {
                            setCheckedSource(!checkedSource);
                        }}
                    />
                    <Text style={{ fontFamily: 'Assistant', fontSize: 16 }}>דרוש מקור</Text>
                </View>

                <Button labelStyle={{ fontFamily: 'AssistantMedium' }} icon="send-outline" mode="contained" style={{ marginTop: 20, width: 200, alignSelf: 'center', backgroundColor: 'rgb(0, 78, 95)' }} onPress={() => submitQuestion()}>שליחה</Button>
            </View>

            <Portal>
                <Dialog visible={visible} onDismiss={hideDialog} style={{ maxWidth: 450, alignSelf: 'center' }}>
                    <Dialog.Title>תודה רבה</Dialog.Title>
                    <Dialog.Content>
                        <Paragraph>שאלתך נשלחה בהצלחה ונשתדל להשיב בהקדם.</Paragraph>
                    </Dialog.Content>
                    <Dialog.Actions>
                        <Button onPress={continueDialog}>סיום</Button>
                    </Dialog.Actions>
                </Dialog>
            </Portal>
        </SafeAreaView>
    )

}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingTop: 40,
    },
    input: {
        fontFamily: 'Assistant',
        height: 40,
        textAlign: 'left',
        flex: 1,
        fontSize: 16,
        borderColor: '#cccccc',
        borderWidth: 1,
        borderRadius: 5,
        padding: 10,
        marginTop: 15,
        color: '#666666',
    },
    textArea: {
        fontFamily: 'Assistant',
        height: 150,
        borderRadius: 5,
        borderColor: '#cccccc',
        borderWidth: 1,
        textAlign: 'left',
        padding: 10,
        fontSize: 16,
        marginTop: 15,
        color: '#666666',
    },
    button: {
        marginHorizontal: 20,
        backgroundColor: '#fafafa',
        marginTop: 20,
        paddingVertical: 10,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
    buttonText: { fontSize: 16, color: 'rgb(19, 125, 149)' },
    authBox: {
        width: '90%',
        maxWidth: 750,
        backgroundColor: '#fafafa',
        borderRadius: 20,
        alignSelf: 'center',
        paddingHorizontal: 14,
        paddingBottom: 30,
        shadowColor: '#aaa',
        shadowOffset: {
            width: 0,
            height: 0.5,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
});