import React from 'react';

import {
    Button,
    Paragraph,
    Dialog,
    Portal,
} from "react-native-paper";

import { deleteChat } from '../../common/UploaderApi'

const DeleteChatDialog = ({ hideDialog, item }) => {
    const deleteFN = () => {
        deleteChat(item)
        hideDialog()
    }
    return (
        <Portal>
            <Dialog visible={item.message} onDismiss={hideDialog} style={{ maxWidth: 450, alignSelf: 'center' }}>
                <Dialog.Content>
                    <Paragraph>האם למחוק את ההודעה?</Paragraph>
                </Dialog.Content>
                <Dialog.Actions>
                    <Button onPress={hideDialog}>ביטול</Button>
                    <Button onPress={deleteFN}>מחיקה</Button>
                </Dialog.Actions>
            </Dialog>
        </Portal>
    )
}

export default DeleteChatDialog