
import { useState, useEffect } from "react";
import { checkAuth } from '../../firebase/UserManagement';
import listQuery from '../../firebase/ListFirestoreManager'
import getByQuery from '../../tagged_posts/SortedPostPerTag'


export default function getPosts(tag) {
    const [posts, setPosts] = useState();
    const [notExist, setNotExist] = useState();

    const currentUser = checkAuth();

    if (tag && !posts && currentUser) {
        async function fetchData() {
            const postsForTag = await getByQuery(listQuery, {
                database: 'posts',
                tag: tag,
            }, tag)
            if (postsForTag.length == 0) {
                setNotExist(true)
            } else {
                setPosts(postsForTag)
            }
        }
        fetchData()
    }

    return [posts, notExist];
}