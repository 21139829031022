import { useState, useEffect } from "react";
import { getDailyLimudSefaria, getTextSefaria } from './SefariaApi'

function getDailyLimud(diaspora) {
    const [dailyLimud, setDailyLimud] = useState();
    useEffect(() => {
        (async () => {
            const dailyLimudSefaria = await getDailyLimudSefaria(diaspora)
            setDailyLimud(dailyLimudSefaria)
        })();
    }, []);
    return dailyLimud
}

function getText(ref) {
    const [text, setText] = useState();
    useEffect(() => {
        (async () => {
            const textSefaria = await getTextSefaria(ref)
            setText(textSefaria)
        })();
    }, []);
    return text
}

export { getDailyLimud, getText }