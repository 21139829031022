
const stopWords = [
    'אבל',
    'או',
    'אולי',
    'אותו',
    'אז',
    'אי',
    'איך',
    'אין',
    'אינו',
    'אך',
    'אל',
    'אם',
    'אני',
    'אע"פ',
    'אעפ"כ',
    'את',
    'אתה',
    'בגלל',
    'בין',
    'בלבד',
    'במקום',
    'בן',
    'בני',
    'בעיקר',
    'בשביל',
    'בשל',
    'בתוך',
    'גם',
    'דבר',
    'האם',
    'הוא',
    'הזה',
    'הי',
    'היא',
    'היה',
    'היו',
    'היום',
    'היי',
    'היכן',
    'הכל',
    'הם',
    'הן',
    'ורק',
    'זאת',
    'זה',
    'זהו',
    'זו',
    'טוב',
    'יהיה',
    'יהיו',
    'יום',
    'יש',
    'כאן',
    'כבר',
    'כי',
    'כך',
    'כ"כ',
    'ככה',
    'כל',
    'כלומר',
    'כמה',
    'כמו',
    'כמו"כ',
    'כן',
    'לא',
    'לה',
    'לי',
    'לכל',
    'למה',
    'למרות',
    'למשל',
    'לפי',
    'לתוך',
    'מאד',
    'מאוד',
    'מאז',
    'מדוע',
    'מדי',
    'מה',
    'מול',
    'מי',
    'מכיוון',
    'מלבד',
    'עבור',
    'עד',
    'עוד',
    'ע"י',
    'על',
    'עם',
    'ע"פ',
    'פי',
    'רק',
    'של',
    'שלנו',
    'שלי',
    'שלה',
    'שלו',
    'האם',
    'צריך',
    'חייב',
    'חייבת',
    'חובה',
    'חייבים',
    'מצווה',
    'מצוה',
    'מצוות',
    'מקיים',
    'לקיים',
    'מקיימים',
    'לעשות',
    'עשיתי',
    'יכול',
    'אפשר',
    'זה',
    'נכון',
    'לא'
]


export default stopWords