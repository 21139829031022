import React from "react";
import { Text } from "react-native"

const SIGN = "*"
const REGEX_BOLD = "\\*(\\S(.*?\\S)?)\\*";
const LINK = "<href>"
const REGEX_POST_LINK = "<href>(\\S(.*?\\S)?)<href>"
const REGEX_MARK = "<em>(\\S(.*?\\S)?)<\\/em>"
const EM = "<em>"
import theme from '../../src/utils/Colors'

function removeStars(str) {
    if (!str || str.indexOf(SIGN) == -1) {
        return str
    }
    var bold = new RegExp(REGEX_BOLD, 'g')
    var html = str.replace(bold, (s) => {
        return s.slice(1, s.length - 1)
    });
    return html
}

function removeLink(str) {
    if (!str || str.indexOf(LINK) == -1) {
        return str
    }
    var link = new RegExp(REGEX_POST_LINK, 'g')
    var html = str.replace(link, '');
    return html
}

function spanBoldAndEm(str) {
    if (!str || str.indexOf(SIGN) == -1) {
        return <Text key={134} style={{ fontFamily: 'Assistant' }}>{spanEM(str)}</Text>
    }
    let match;
    const regexp = new RegExp(REGEX_BOLD, 'g');
    let start = 0
    const texts = []
    let i = 0
    while ((match = regexp.exec(str)) !== null) {
        //console.log(`Found bold ${match[0]} start=${match.index} end=${regexp.lastIndex}.`);
        if (match.index > 0) {
            texts.push(spanEM(str.slice(start, match.index)))
        }
        texts.push(<Text key={(i++).toString()} style={{ fontFamily: 'AssistantBold' }}>{spanEM(match[1])}</Text>)
        start = regexp.lastIndex
    }
    texts.push(spanEM(str.slice(start)))
    return <Text key={133} style={{ fontFamily: 'Assistant' }}>{texts}</Text>
}

function spanBoldAndLink(str, onLinkPressed) {
    if (!str || str.indexOf(SIGN) == -1) {
        return <Text key={130} style={{ fontFamily: 'Assistant' }}>{spanLink(str, onLinkPressed)}</Text>
    }
    let match;
    const regexp = new RegExp(REGEX_BOLD, 'g');
    let start = 0
    const texts = []
    let i = 0
    while ((match = regexp.exec(str)) !== null) {
        //console.log(`Found bold ${match[0]} start=${match.index} end=${regexp.lastIndex}.`);
        if (match.index > 0) {
            texts.push(spanLink(str.slice(start, match.index), onLinkPressed))
        }
        texts.push(<Text key={(i++).toString()} style={{ fontFamily: 'AssistantBold' }}>{spanLink(match[1], onLinkPressed, 'AssistantBold')}</Text>)
        start = regexp.lastIndex
    }
    texts.push(spanLink(str.slice(start), onLinkPressed))
    return <Text key={132} style={{ fontFamily: 'Assistant' }}>{texts}</Text>
}

function spanEM(str, font_family = 'Assistant') {
    if (!str || str.indexOf(EM) == -1) {
        return str
    }
    let match;
    const regexp = new RegExp(REGEX_MARK, 'g');
    let start = 0
    const texts = []
    let i = 1000
    while ((match = regexp.exec(str)) !== null) {
        //console.log(`Found em ${match[0]} start=${match.index} end=${regexp.lastIndex}.`);
        if (match.index > 0) {
            texts.push(str.slice(start, match.index))
        }
        texts.push(<Text key={(i++).toString()} style={{ backgroundColor: '#ffff00', fontFamily: font_family }}>{match[1]}</Text>)
        start = regexp.lastIndex
    }
    texts.push(str.slice(start))
    return <Text key={131} style={{ fontFamily: font_family }}>{texts}</Text>
}

function spanLink(str, onLinkPressed, font_family = 'Assistant') {
    if (!str || str.indexOf(LINK) == -1) {
        return str
    }
    let match;
    const regexp = new RegExp(REGEX_POST_LINK, 'g');
    let start = 0
    const texts = []
    let i = 1000
    while ((match = regexp.exec(str)) !== null) {
        //console.log(`Found link ${match[0]} start=${match.index} end=${regexp.lastIndex}.`);
        if (match.index > 0) {
            texts.push(str.slice(start, match.index))
        }
        const link = match[1]
        texts.push(<Text onPress={() => onLinkPressed(link)} key={(i++).toString()} style={{ color: theme.accent, fontFamily: font_family, textDecoration: 'underline' }}>לחץ כאן</Text>)
        start = regexp.lastIndex
    }
    texts.push(str.slice(start))
    return <Text style={{ fontFamily: font_family }}>{texts}</Text>
}


export { spanBoldAndEm, removeStars, spanEM, spanLink, removeLink, spanBoldAndLink }