import { useState, useEffect } from 'react';
import listQuery from '../../firebase/ListFirestoreManager'
import { checkAuth } from "../../firebase/UserManagement";


export default function getPostsBySubject(subject) {
    const [posts, setPosts] = useState();
    const [notExist, setNotExist] = useState();
    const currentUser = checkAuth();
    useEffect(() => {
        if (!subject) {
            setNotExist(true)
        } else if (subject && !posts && currentUser) {
            async function fetchData() {
                const postsForSubject = await listQuery({
                    database: 'posts',
                    where: [
                        {
                            field: "subject",
                            operator: "==",
                            value: subject,
                        },
                        {
                            field: "searchable",
                            operator: "==",
                            value: true,
                        },
                    ]
                }).get()
                const result = postsForSubject.docs.map((doc) => ({ key: doc.id, ...doc.data() }))
                if (result && result.length > 0) {
                    setPosts(result)
                } else {
                    setNotExist(true)
                }
            }
            fetchData()
        }
    })

    return [posts, notExist];
}