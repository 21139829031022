import React from "react";
import { SafeAreaView } from "react-native";

import ConversationsList from "./ConversationsList";

const ConversationsScreen = ({ route, navigation }) => {
    const { questionKey } = route.params;
    console.log(questionKey)
    return (
        <SafeAreaView style={{ flex: 1 }}>
            <ConversationsList questionKey={questionKey} navigation={navigation} />
        </SafeAreaView>
    )
}

export default ConversationsScreen